import {Exercise, ExerciseSet, Workout} from "../logic/exercises";
import {createExerciseDiv, getDataStorage, getWorkoutPlanner} from "./tools";
import {showLoading, showPage} from "./controller";

async function createWorkoutDiv(workout: Workout): Promise<HTMLDivElement> {

    let workoutElement = document.createElement('div');
    workoutElement.className = 'exercise';

    let nameElement = document.createElement('h3');
    nameElement.textContent = workout.date.toDateString();
    nameElement.classList.add("workout-header");
    workoutElement.appendChild(nameElement);

    let dElements = document.createElement('ul');
    for (let d of await getWorkoutPlanner().getWorkoutSummary(workout)) {
        let dElement = document.createElement('li');
        dElement.textContent = d;
        dElements.appendChild(dElement);
    }

    workoutElement.appendChild(dElements);

    workoutElement.addEventListener('click', () => showWorkout(workout));

    return workoutElement;

}


export async function showHistoryPage() {
    showLoading("Fetching workouts");
    const page = document.getElementById('other-page')!;
    page.innerHTML = "";
    const header = document.createElement("h2");
    header.innerText = "Workout history";
    page.appendChild(header);
    for (let workout of await getDataStorage().getWorkouts()) {
        page.appendChild(await createWorkoutDiv(workout));
    }
    showPage('other-page', showHistoryPage);
}

async function showWorkout(workout: Workout) {
    showPage('other-page', () => showWorkout(workout));
    const page = document.getElementById('other-page')!;
    page.innerHTML = "";

    let nameElement = document.createElement('h3');
    nameElement.textContent = workout.date.toDateString();
    nameElement.classList.add("workout-header");
    page.appendChild(nameElement);

    for (let i = 0; i < workout.sets.length; ++i) {
        const exerciseSet: ExerciseSet = workout.sets[i];
        const exercise: Exercise = await getDataStorage().getExercise(exerciseSet.exerciseId);
        page.appendChild(createExerciseDiv(exerciseSet, exercise, true, true, true));
    }

}

