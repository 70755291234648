import {showLoading, showPage} from "./controller";
import {Chart} from 'chart.js/auto'
import {ChartFactory, getDataStorage, smooth} from "./tools";
import {DAY_IN_MILLIS} from "../logic/helper";
import {Muscle, Status} from "../logic/exercises";
import {renderHuman} from "./humanModel";

let chart1: Chart | null;
let chart2: Chart | null;

export async function showStatisticsPage() {

    showLoading("Fetching workouts");

    const workouts = await getDataStorage().getWorkouts();

    showLoading("Analyzing workouts");

    if (workouts.length == 0) {
        return;
    }

    const muscleVolume: Record<Muscle, number> = {} as Record<Muscle, number>;
    for (const [, muscle] of Object.entries(Muscle)) {
        muscleVolume[muscle] = 0;
    }
    // const muscleVolume = new Array(Object.entries(Muscle).length).fill(0);
    for (const exercise of await getDataStorage().getExercises(true)) {
        for (const [, muscle] of Object.entries(Muscle)) {
            muscleVolume[muscle] += exercise.getMuscleWeight(muscle) * exercise.getVolumeModifier(false, true) * exercise.getSetsPerWeek();
        }
    }

    // const muscleScaledVolume = Object.fromEntries(Object.entries(muscleVolume).map(([muscle, volume]) => [muscle,Math.min(1,volume/20)])) as Record<Muscle, number>;

    document.getElementById("statistics-human")!.innerHTML = "";
    document.getElementById("statistics-human")!.appendChild(renderHuman(false, muscleVolume));
    document.getElementById("statistics-human")!.appendChild(renderHuman(true, muscleVolume));

    const startDate = new Date(workouts[workouts.length - 1].date);
    startDate.setHours(0);
    startDate.setMinutes(0);

    const now = new Date(Math.max(new Date().getTime() - DAY_IN_MILLIS, workouts[0].date.getTime()));

    let data = [];
    for (let i = 0; i * DAY_IN_MILLIS + startDate.getTime() < now.getTime(); ++i) {
        data[i] = {
            date: new Date(startDate.getTime() + i * DAY_IN_MILLIS).toDateString(),
            weeklyVolumeByMuscle: new Array(Object.entries(Muscle).length).fill(0),
            weeklyVolume: 0,
            weeklyHours: 0,
            weeklyFrequency: 0,
        }
    }

    for (let w of workouts) {
        let dateIndex = Math.floor((w.date.getTime() - startDate.getTime()) / DAY_IN_MILLIS);
        let sessionStartDate = w.sets[0].date.getTime();
        let sessionEndDate = w.sets[0].date.getTime();
        for (let xs of w.sets) {
            const exercise = await getDataStorage().getExercise(xs.exerciseId);
            if (xs.status != Status.Skipped) {
                let volume = xs.getVolume() * exercise.getVolumeModifier(true, false);
                let muscleIndex = 0;
                for (const [, muscle] of Object.entries(Muscle)) {
                    data[dateIndex].weeklyVolumeByMuscle[muscleIndex] += exercise.getMuscleWeight(muscle) * volume * 7;
                    ++muscleIndex;
                }
                data[dateIndex].weeklyVolume += volume * 7;
            }
            if (xs.status != Status.Skipped) {
                sessionStartDate = Math.min(sessionStartDate, xs.date.getTime());
                sessionEndDate = Math.max(sessionEndDate, xs.date.getTime());
            }
        }
        data[dateIndex].weeklyHours += 7 * (sessionEndDate - sessionStartDate) / (1000 * 60 * 60);
        data[dateIndex].weeklyFrequency += 7;
    }

    const opacity = 0.1;
    const window = 4;

    if (chart1) {
        chart1.destroy();
    }
    const chartFactory1 = new ChartFactory("statistics-canvas1", data.map(row => row.date));
    chartFactory1.setLegends(false);
    let index = 0;
    for (const [, muscle] of Object.entries(Muscle)) {
        const muscleData = smooth(data.map(row => row.weeklyVolumeByMuscle[index]), window);
        chartFactory1.addLine(muscle, muscleData, undefined);
        ++index;
    }
    chart1 = chartFactory1.create();

    if (chart2) {
        chart2.destroy();
    }
    const chartFactory2 = new ChartFactory("statistics-canvas2", data.map(row => row.date));
    chartFactory2.addLine("Weekly hours", smooth(data.map(row => row.weeklyHours), window), "red" , );
    chartFactory2.addLine("Workouts per week", smooth(data.map(row => row.weeklyFrequency), window), "blue");
    chartFactory2.addLine("Weekly hours (raw)", data.map(row => row.weeklyHours), `rgba(255,0,0,${opacity}`, true);
    chartFactory2.addLine("Workouts per week (raw)", data.map(row => row.weeklyFrequency), `rgba(0,0,255,${opacity}`, true);
    chart2 = chartFactory2.create();

    showPage('statistics-page', showStatisticsPage);

}