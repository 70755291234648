import {assert, DAY_IN_MILLIS} from "./helper";
import {
    MINIMUM_REPS_FOR_MUSCLE_GROWTH,
    REPS_PER_DAY_INCREASE,
    SKILL_SUCCESS_RATE,
    TESTING_SET_RIR,
    WORKING_SET_RIR
} from "./workoutPlanner";

export type DocumentId<T> = string;

export enum WorkoutTrait {
    Psyched = "Psyched", // increase intensity
    External = "Other exercise",
    Hurry = "Hurry", // decrease volume
    Fatigued = "Fatigued", // decrease volume and intensity
    Tired = "Tired", // decrease intensity
}

export function getWorkoutTraitExplanation(trait: WorkoutTrait): string {
    if (trait == WorkoutTrait.Psyched) {
        return "You feel like trying really hard";
    } else if (trait == WorkoutTrait.External) {
        return "You have participated in some strenous exercise since last session.";
    } else if (trait == WorkoutTrait.Hurry) {
        return "You need a short workout, mostly to maintain routine.";
    } else if (trait == WorkoutTrait.Fatigued) {
        return "You feel like you cannot give a good effort.";
    } else if (trait == WorkoutTrait.Tired) {
        return "You would rather sleep, or slept very poorly last night.";
    } else {
        throw new Error("unknown trait " + trait);
    }
}

export enum Muscle {
    // PUSH
    PECTORALIS_MAJOR = "pectoralis major",
    POSTERIOR_DELTOIDS = "posterior deltoids",
    MEDIAL_DELTOIDS = "medial deltoids",
    TRICEPS_BRACHII = "triceps brachii",
    SERRATUS_ANTERIOR = "serratus anterior",

    // PULL
    TRAPEZIUS = "trapezius",
    // RHOMBOIDS = "rhomboids",
    LATISSIMUS_DORSI = "latissimus dorsi",
    // TERES_MAJOR = "teres major",
    ERECTOR_SPINAE = "erector spinae",
    ANTERIOR_DELTOIDS = "anterior deltoids",
    BICEPS_BRACHII = "biceps brachii",
    // BRACHIALIS = "brachialis",
    FOREARM_MUSCLES = "forearm muscles",

    // LEGS
    QUADRICEPS = "quadriceps",
    HAMSTRINGS = "hamstrings",
    GLUTEAL_MUSCLES = "gluteal muscles",
    CALVES = "calves",
    TIBIALIS_ANTERIOR = "tibialis anterior",
    HIP_ADDUCTORS = "hip adductors",
    HIP_ABDUCTORS = "hip abductors",
    RECTUS_ABDOMINIS = "rectus abdominis",
    OBLIQUES = "obliques",
}

// source https://mennohenselmans.com/what-are-the-biggest-and-smallest-muscles-in-the-body/ + Chat GPT 4.
const muscleSize: { [key in Muscle]: number } = {
    // PUSH
    [Muscle.PECTORALIS_MAJOR]: 260,
    [Muscle.ANTERIOR_DELTOIDS]: 150,
    [Muscle.MEDIAL_DELTOIDS]: 150,
    [Muscle.TRICEPS_BRACHII]: 237,
    [Muscle.SERRATUS_ANTERIOR]: 150,

    // PULL
    [Muscle.TRAPEZIUS]: 217 + 100,
    // [Muscle.RHOMBOIDS]: 100,
    [Muscle.LATISSIMUS_DORSI]: 244 + 75,
    // [Muscle.TERES_MAJOR]: 75,
    [Muscle.ERECTOR_SPINAE]: 700,
    [Muscle.POSTERIOR_DELTOIDS]: 150,
    [Muscle.BICEPS_BRACHII]: 112 + 150,
    // [Muscle.BRACHIALIS]: 150,
    [Muscle.FOREARM_MUSCLES]: 400,

    [Muscle.QUADRICEPS]: 1791,
    [Muscle.HAMSTRINGS]: 724,
    [Muscle.GLUTEAL_MUSCLES]: 1204,
    [Muscle.CALVES]: 849,
    [Muscle.TIBIALIS_ANTERIOR]: 200,
    [Muscle.HIP_ADDUCTORS]: 350,
    [Muscle.HIP_ABDUCTORS]: 275,
    [Muscle.RECTUS_ABDOMINIS]: 250,
    [Muscle.OBLIQUES]: 250,
};

export function getPushMuscles(): Muscle[] {
    return [
        Muscle.PECTORALIS_MAJOR,
        Muscle.ANTERIOR_DELTOIDS,
        Muscle.MEDIAL_DELTOIDS,
        Muscle.TRICEPS_BRACHII,
        Muscle.SERRATUS_ANTERIOR,
        Muscle.RECTUS_ABDOMINIS,
        Muscle.OBLIQUES
    ];
}

export function getPullMuscles(): Muscle[] {
    return [
        Muscle.LATISSIMUS_DORSI,
        // Muscle.RHOMBOIDS,
        Muscle.TRAPEZIUS,
        Muscle.ERECTOR_SPINAE,
        Muscle.POSTERIOR_DELTOIDS,
        Muscle.BICEPS_BRACHII,
        // Muscle.BRACHIALIS,
        // Muscle.TERES_MAJOR,
        Muscle.FOREARM_MUSCLES
    ];
}

export function getLegMuscles(): Muscle[] {
    return [
        Muscle.QUADRICEPS,
        Muscle.HAMSTRINGS,
        Muscle.GLUTEAL_MUSCLES,
        Muscle.CALVES,
        Muscle.TIBIALIS_ANTERIOR,
        Muscle.HIP_ADDUCTORS,
        Muscle.HIP_ABDUCTORS
    ];
}


//noinspection JSUnusedGlobalSymbols
export enum Modality {
    ACTIVE = "active",
    ISOMETRIC = "isometric", // play metronome
    UNILATERAL = "unilateral", // remind to do both sides, weaker side first
    STRENGTH = "strength",
    HYPERTROPHY = "hypertrophy",
    MOBILITY = "mobility", // can always do partial sets, at most one set per workout, no testing sets, shorter rest periods
    SKILL = "skill", // first in the workout, fractional reps, no testing and failure does not decrease volume.
    CONDITIONING = "conditioning", // when workout limit is below maximum set reps
    // Maintenance = "Maintenance", // not in use atm
    // Pain = "Pain", // when workout limit is below maximum set reps
}

export function getModalityExplanation(modality: Modality): string {
    if (modality == Modality.ACTIVE) {
        return "This exercise is included in workouts.";
    } else if (modality == Modality.ISOMETRIC) {
        return "Seconds spent in hold instead of reps, provides a metronome to count time during exercise.";
    } else if (modality == Modality.UNILATERAL) {
        return "Single limb exercise, separate sets for each side.";
    } else if (modality == Modality.STRENGTH) {
        return "Between 3 and 8 maximum repetitions.";
    } else if (modality == Modality.HYPERTROPHY) {
        return "Between 5 and 20 maximum repetitions.";
    } else if (modality == Modality.MOBILITY) {
        return "Limited by range of motion rather than strength.";
    } else if (modality == Modality.SKILL) {
        return "Limited by balance or coordination rather than strength. Scheduled early in the session, reps adjusted so you can expect to fail 25% of the time.";
    } else if (modality == Modality.CONDITIONING) {
        return "Repetitions are temporarily decreased.";
    } else {
        throw new Error("unknown modality " + modality);
    }

}

export enum Status {
    Pending = "Pending",
    Skipped = "Skipped",
    Failed = "Failed",
    Completed = "Completed",
}

export class ExerciseState {

    constructor(
        public workoutDate: Date,// when was last workout
        public repsPerDay: number,// reps per day at latest workout.
        public maximumRepsPerSet: number, // reps where failed.
        public maximumRepsPerWorkout: number, // maximum reps done per workout on average, weight higher a lot more.

        public testDate: Date,// when was the last date we did over 2 RIR of heavier?
        public testRepsInReserve: number // how many reps have we added.
    ) {
    }

}

export class UserState {

    public localRecovery = 1;
    public cardioRecovery = 1;
    public workoutDates: Date[] = [];
    public dataVersion = 1;

    getWorkoutZ(date: Date): number {
        let diffs = []
        for (let i = 1; i < this.workoutDates.length; ++i) {
            diffs.push(this.workoutDates[i].getTime() - this.workoutDates[i - 1].getTime());
        }
        let mean = diffs.reduce((a, b) => a + b, 0) / diffs.length;
        let diffsSq = diffs.map((x) => (mean - x) ** 2);
        let stddev = Math.sqrt(diffsSq.reduce((a, b) => a + b, 0) / diffsSq.length);
        let latest = date.getTime() - this.workoutDates[this.workoutDates.length - 1].getTime();
        return (latest - mean) / stddev;
    }

}

export class Exercise {

    constructor(
        public id: DocumentId<Exercise> = "",
        public name: string = "",
        public details: string = "",
        public url: string = "",
        private modalities: Modality[] = [],
        private primary: Muscle[] = [],
        private secondary: Muscle[] = [],
        public state: ExerciseState = new ExerciseState(new Date(new Date().getTime() - DAY_IN_MILLIS), 8, 8, 3, new Date(new Date().getTime() - DAY_IN_MILLIS), WORKING_SET_RIR - 1),
    ) {
    }

    getWorkoutDate(): Date {
        return this.state.workoutDate;
    }

    getTestingDate(): Date {
        return this.state.testDate;
    }

    getSetsPerWeek(): number {
        // return this.state.repsPerDay * 7 / this.getWorkingSetReps();
        return this.state.repsPerDay * 7 / this.state.maximumRepsPerSet;
    }

    public setSetsPerWeek(setsPerWeek: number) {
        // this.state.repsPerDay = ( setsPerWeek * this.getWorkingSetReps() ) / 7;
        this.state.repsPerDay = (setsPerWeek * this.state.maximumRepsPerSet) / 7;
    }

    getWorkingSetReps(date: Date): number {
        let workingSet = Math.max(1, this.state.maximumRepsPerSet - WORKING_SET_RIR);
        if (this.isModality(Modality.SKILL) || this.isModality(Modality.MOBILITY)) {
            workingSet = this.state.maximumRepsPerSet;
        }
        const pending = Math.max(1, Math.ceil(this.getRepsPending(date)));
        if (pending < workingSet && date.getTime() > this.getMaximumWorkoutDate().getTime()) {
            // not enough reps for actual set, and are already past the maximum workout date.
            return pending;
        } else {
            return Math.max(1, Math.ceil(workingSet));
        }
    }

    getTestingSetReps(): number {
        let workingSet = Math.max(1, this.state.maximumRepsPerSet - WORKING_SET_RIR);
        return Math.ceil(Math.max(1 + workingSet, this.state.maximumRepsPerSet - Math.min(TESTING_SET_RIR, this.state.testRepsInReserve)));
    }

    getRepsPending(now: Date): number {
        const daysSincePrevious = (now.getTime() - this.state.workoutDate.getTime()) / DAY_IN_MILLIS;
        let totalReps = this.state.repsPerDay * (REPS_PER_DAY_INCREASE ** daysSincePrevious - 1) * REPS_PER_DAY_INCREASE / (REPS_PER_DAY_INCREASE - 1)
        if (totalReps > this.state.maximumRepsPerWorkout) {
            const overshootReps = totalReps - this.state.maximumRepsPerWorkout;
            totalReps = Math.ceil(this.state.maximumRepsPerWorkout ** 2 / (this.state.maximumRepsPerWorkout + overshootReps));
        }
        return Math.ceil(totalReps);
    }

    clearModalities() {
        return this.modalities = []
    }

    isModality(modality: Modality): boolean {
        return this.modalities.includes(modality);
    }

    setModality(modality: Modality, enabled: boolean) {
        if (enabled != this.isModality(modality)) {
            if (enabled) {
                this.modalities.push(modality);
            } else {
                this.modalities = this.modalities.filter((x) => x != modality);
            }
        }
    }

    getModalities(): readonly Modality[] {
        return this.modalities;
    }

    private getSetsPerWeekRange(): number[] {
        if (this.isModality(Modality.STRENGTH)) {
            return [2, 3, 7, 14];
        } else if (this.isModality(Modality.MOBILITY)) {
            return [5, 7, 12, 14];
        } else if (this.isModality(Modality.SKILL)) {
            return [5, 7, 35, 49];
        } else {
            return [1, 1, 100, 100];
        }
    }

    public getWarnings(includeName:boolean): string[] {
        let warnings = [];
        if( this.isModality(Modality.MOBILITY)) {
            if (this.state.maximumRepsPerSet < 5) {
                if (includeName) {
                    warnings.push( this.name + " has too few repetititons!");
                } else {
                    warnings.push("Too few repetititons!");
                }
            } else if (this.state.maximumRepsPerSet > 30) {
                if (includeName) {
                    warnings.push( this.name + " has too many repetititons!");
                } else {
                    warnings.push("Too many repetititons!");
                }
            }
        }

        const reps = Math.max(1, this.state.maximumRepsPerSet - 3);
        const setsPerWeek = this.state.repsPerDay * 7 / reps;

        if (setsPerWeek < this.getSetsPerWeekRange()[0]) {
            if (includeName) {
                warnings.push(this.name + " has too few sets for " + this.getType() + "." );
            } else {
                warnings.push("Too few sets for " + this.getType() + "." );
            }
        } else if (setsPerWeek < this.getSetsPerWeekRange()[1]) {
            if (includeName) {
                warnings.push(this.name + " has slightly too few sets for " + this.getType() + "." );
            } else {
                warnings.push("Slightly too few sets for " + this.getType() + "." );
            }
        } else if (setsPerWeek > this.getSetsPerWeekRange()[3]) {
            if (includeName) {
                warnings.push(this.name + " has too many sets for " + this.getType() + "." );
            } else {
                warnings.push("Too many sets for " + this.getType() + "." );
            }
        } else if (setsPerWeek > this.getSetsPerWeekRange()[2]) {
            if (includeName) {
                warnings.push(this.name + " has slightly too many sets for " + this.getType() + "." )
            } else {
                warnings.push("Slightly too many sets for " + this.getType() + "." )
            }
        }
        return warnings;
    }

    public getType() : string {
        if( this.isModality(Modality.STRENGTH)) {
            return "strength training";
        }
        if( this.isModality(Modality.SKILL)) {
            return "skill practice";
        }
        if( this.isModality(Modality.MOBILITY)) {
            return "mobility work";
        }
        return "...";
    }

    public getPerRepProbability(): number {
        if (this.state.maximumRepsPerSet < 1) {
            return this.state.maximumRepsPerSet * SKILL_SUCCESS_RATE;
        } else {
            return SKILL_SUCCESS_RATE ** (1 / this.state.maximumRepsPerSet);
        }
    }

    public getVolumeModifier(unilateral: boolean, repetitions: boolean): number {
        let volume = 1;
        if (unilateral && this.isModality(Modality.UNILATERAL)) {
            volume /= 2;
        }
        if (repetitions) {
            volume *= this.state.maximumRepsPerSet / Math.max(this.state.maximumRepsPerSet, MINIMUM_REPS_FOR_MUSCLE_GROWTH);
        }
        if (this.isModality(Modality.MOBILITY)) {
            volume /= 2;
        }
        if (this.isModality(Modality.SKILL)) {
            volume /= 2;
        }
        return volume;
    }

    /**
     * @return days between workouts based on maximum reps per workout and reps per day
     */
    public getWorkoutFrequency(): number {
        return this.state.maximumRepsPerWorkout / this.state.repsPerDay;
    }

    /**
     * @param workout the workout for which to calculate the priority
     * @return how many full workouts, since last workout..
     */
    public getPriority(workout: Workout): number {
        return (workout.date.getTime() - this.state.workoutDate.getTime()) / (this.getWorkoutFrequency() * DAY_IN_MILLIS);
    }

    public getPrimaryMuscles(): Muscle[] {
        return this.primary;
    }

    public getSecondaryMuscles(): Muscle[] {
        return this.secondary;
    }

    public getMuscleWeight(muscle: Muscle): number {
        if (this.primary.includes(muscle)) {
            return 1;
        } else if (this.secondary.includes(muscle)) {
            return 1 / 2;
        } else {
            return 0;
        }
    }

    public isPrimaryMuscle(muscle: Muscle): boolean {
        return this.primary.includes(muscle);
    }

    public isSecondaryMuscle(muscle: Muscle): boolean {
        return this.secondary.includes(muscle);
    }

    public setPrimaryMuscles(muscles: Muscle[]) {
        this.primary = muscles;
    }

    public setSecondaryMuscles(muscles: Muscle[]) {
        this.secondary = muscles;
    }

    public getMuscleVolume(): number {
        let volume = 0;
        for (const muscle of this.primary) {
            if( muscleSize[muscle]) {
                volume += muscleSize[muscle];
            }
        }
        for (const muscle of this.secondary) {
            if( muscleSize[muscle]) {
                volume += muscleSize[muscle] / 3;
            }
        }
        return volume;
    }

    /**
     * when daily accumulated reps reaches max reps per workout.
     */
    getMaximumWorkoutDate(): Date {
        // let daysUntil = this.state.maximumRepsPerWorkout/this.state.repsPerDay;
        let daysUntil = Math.log(1 + ((REPS_PER_DAY_INCREASE - 1) * Math.max(this.state.maximumRepsPerSet,this.state.maximumRepsPerWorkout)) / (REPS_PER_DAY_INCREASE * this.state.repsPerDay)) / Math.log(REPS_PER_DAY_INCREASE);
        return new Date(this.state.workoutDate.getTime() + daysUntil * DAY_IN_MILLIS);
    }

    /**
     * date when the break between sessions becomes too long, ie 2x the days to max( 1 working set, max workout reps, 2 ).
     */
    public getTooLongBetweenWorkoutsDate() {
        const daysToMaximumWorkout = (this.getMaximumWorkoutDate().getTime() - this.state.workoutDate.getTime()) / DAY_IN_MILLIS;
        const daysToWorkingSet = Math.max(1, this.state.maximumRepsPerSet - WORKING_SET_RIR) / this.state.repsPerDay;
        const maxAllowedDaysSinceWorkout = 2 * Math.max(2, daysToMaximumWorkout, daysToWorkingSet );
        return new Date( this.state.workoutDate.getTime() + maxAllowedDaysSinceWorkout * DAY_IN_MILLIS );
    }

}

export class ExerciseSet {
    constructor(
        public exerciseId: DocumentId<Exercise>,
        public reps: number,
        public status: Status,
        public secondary: boolean,
        public rir: number,
        public date: Date,
    ) {
    }

    public getVolume() {
        return this.reps / Math.max(MINIMUM_REPS_FOR_MUSCLE_GROWTH, this.reps + this.rir);
    }

    public secondaryCopy(): ExerciseSet {
        assert(!this.secondary);
        return new ExerciseSet(this.exerciseId, this.reps, this.status, true, this.rir, this.date);
    }

}

export class Workout {
    constructor(
        public id: DocumentId<Workout>,
        public date: Date,
        public sets: ExerciseSet[],
        public traits: WorkoutTrait[],
        public completed: boolean
    ) {
    }

    hasStarted(): boolean {
        for (let xs of this.sets) {
            if (xs.status != Status.Pending) {
                return true;
            }
        }
        return false;
    }

    hasNextExerciseSet(): boolean {
        for (let xs of this.sets) {
            if (xs.status == Status.Pending) {
                return true;
            }
        }
        return false;
    }

    isTrait(trait: WorkoutTrait): boolean {
        return this.traits.includes(trait);
    }

    public getRelativeRest(): number {
        let rest = 1;
        if (this.isTrait(WorkoutTrait.Fatigued)) {
            rest *= 3 / 2;
        }
        if (this.isTrait(WorkoutTrait.Hurry)) {
            rest *= 2 / 3
        }
        return rest;
    }

    getVolume(): number {
        let volume = 1;
        if (this.isTrait(WorkoutTrait.Fatigued)) {
            volume *= 2 / 3;
        }
        if (this.isTrait(WorkoutTrait.Tired)) {
            volume *= 2 / 3;
        }
        if (this.isTrait(WorkoutTrait.Hurry)) {
            volume *= 1 / 3;
        }
        if (this.isTrait(WorkoutTrait.External)) {
            volume *= 2 / 3;
        }
        return volume;
    }

}

