import {DocumentId, Exercise, getModalityExplanation, Modality, Muscle, Status} from "../logic/exercises";
import {
    ChartFactory,
    createCheckBox,
    exerciseMuscles,
    getDataStorage,
    makeElementProgressBar,
    makeToolTip,
    readCheckboxes,
    smooth
} from "./tools";
import {showLoading, showMessage, showPage} from "./controller";
import {DAY_IN_MILLIS} from "../logic/helper";
import Chart from "chart.js/auto";
import {WORKING_SET_RIR} from "../logic/workoutPlanner";

function showExercise(exercise: Exercise, showExercisesPage: HTMLDivElement) {
    let exerciseElement = document.createElement('div');
    exerciseElement.className = 'exercise';

    const workingSetReps = Math.max(1, exercise.state.maximumRepsPerSet - WORKING_SET_RIR);
    const workingSetsPerWeek = exercise.state.repsPerDay * 7 / workingSetReps;

    let nameElement = document.createElement('h3');
    nameElement.textContent = exercise.name + " (" + workingSetsPerWeek.toFixed() + "x" + Math.ceil(workingSetReps).toFixed() + " " + (exercise.isModality(Modality.ISOMETRIC) ? "secs" : "reps") + ")";
    if (exercise.isModality(Modality.SKILL)) {
        nameElement.textContent += " (" + (100 * (exercise.getPerRepProbability() ** Math.ceil(exercise.state.maximumRepsPerSet))).toFixed() + "%)";
    }
    nameElement.classList.add("exercise-header");
    let progress = exercise.getRepsPending(new Date()) / exercise.state.maximumRepsPerWorkout;
    let color;
    if (progress < 0) {
        color = "#ff8888";
    } else if (exercise.getMaximumWorkoutDate() > new Date()) {
        color = "#88ff88";
    } else {
        color = "#ffff88";
    }
    makeElementProgressBar(nameElement, Math.min(1, Math.abs(progress)), color);
    exerciseElement.appendChild(nameElement);

    let detailsElement = document.createElement('p');
    detailsElement.classList.add("description");
    if (exercise.details.length != 0) {
        detailsElement.innerHTML += exercise.details;
        detailsElement.innerHTML += "</br>";
        detailsElement.innerHTML += "</br>";
    }
    let primaryStr = exercise.getPrimaryMuscles().join(", ");
    let secondaryStr = exercise.getSecondaryMuscles().join(", ");
    let modalitiesStr = exercise.getModalities().filter((m) => m != Modality.ACTIVE).join(", ");

    if (primaryStr == "") {
        primaryStr = "unknown";
        secondaryStr = "unknown";
    } else if (secondaryStr == "") {
        secondaryStr = "none";
    }
    if (modalitiesStr == "") {
        modalitiesStr = "none";
    }

    detailsElement.innerHTML += '<span class="list-label">Primary muscles :</span> ' + primaryStr;
    detailsElement.innerHTML += "</br>";
    detailsElement.innerHTML += '<span class="list-label">Secondary muscles :</span> ' + secondaryStr;
    detailsElement.innerHTML += "</br>";
    detailsElement.innerHTML += '<span class="list-label">Modalities :</span> ' + modalitiesStr;
    exerciseElement.appendChild(detailsElement);

    let modalitiesElement = document.createElement('ul');
    for (let warning of exercise.getWarnings(false)) {
        let warningElement = document.createElement('li');
        if (warning.endsWith("eady for training")) {
            warningElement.classList.add("encourage");
        } else {
            warningElement.classList.add("warning");
        }
        warningElement.textContent = warning;
        modalitiesElement.appendChild(warningElement);
    }
    exerciseElement.appendChild(modalitiesElement);

    exerciseElement.addEventListener('click', () => editExercise(exercise));

    showExercisesPage.appendChild(exerciseElement);
}

export async function showExercises() {
    showPage('show-exercises-page', showExercises);
    let activeExercises = document.getElementById('active-exercises-list')! as HTMLDivElement;

    // Remove previous exercises if any
    while (activeExercises.firstChild) {
        activeExercises.firstChild.remove();
    }
    for (let exercise of (await getDataStorage().getExercises(true)).sort((a, b) => a.name.localeCompare(b.name))) {
        showExercise(exercise, activeExercises);
    }
    let inactiveExercises = document.getElementById('inactive-exercises-list')! as HTMLDivElement;

    // Remove previous exercises if any
    while (inactiveExercises.firstChild) {
        inactiveExercises.firstChild.remove();
    }
    for (let exercise of (await getDataStorage().getExercises(false)).sort((a, b) => a.name.localeCompare(b.name))) {
        showExercise(exercise, inactiveExercises);
    }
}

export async function addExercise() {
    const exercise: Exercise = new Exercise();
    await editExercise(exercise);
}

let chart: Chart | null;

async function showChart(exercise: Exercise) {

    const workouts = await getDataStorage().getWorkouts();

    if (workouts.length == 0) {
        document.getElementById("edit-exercise-canvas")!.parentElement!.classList.add("hidden");
        return;
    }

    document.getElementById("edit-exercise-canvas")!.parentElement!.classList.remove("hidden");

    let startDate = null;
    let endDate = null;

    for (let w of workouts) {
        for (let s of w.sets) {
            if (s.exerciseId == exercise.id) {
                startDate = w.date;
                if (!endDate) {
                    endDate = w.date;
                }
                break;
            }
        }
    }

    if (!startDate || !endDate) {
        return;
    }

    endDate = new Date();

    startDate.setHours(0);
    startDate.setMinutes(0);

    let data = [];
    for (let i = 0; i * DAY_IN_MILLIS + startDate.getTime() <= endDate.getTime(); ++i) {
        data[i] = {
            date: new Date(startDate.getTime() + i * DAY_IN_MILLIS).toDateString(),
            totalReps: 0,
            maxReps: 0,
            maxRepsSmooth: NaN,
            failedReps: 0,
            sets: 0,
            frequency: 0,
            time: 0
        }
    }

    for (let w of workouts) {
        const dateIndex = Math.floor((w.date.getTime() - startDate.getTime()) / DAY_IN_MILLIS);
        if (dateIndex < 0 || dateIndex > data.length - 1) {
            continue;
        }
        const entry = data[dateIndex];
        for (let i = 0; i < w.sets.length; ++i) {
            let xs = w.sets[i];
            if (xs.exerciseId == exercise.id) {
                if (xs.status != Status.Skipped && xs.status != Status.Pending) {
                    if (exercise.isModality(Modality.UNILATERAL)) {
                        entry.totalReps += xs.reps / 2;
                        entry.sets += 0.5;
                    } else {
                        entry.totalReps += xs.reps;
                        entry.sets += 1;
                    }
                    if (xs.status == Status.Completed) {
                        entry.maxReps = Math.max(entry.maxReps, xs.reps);
                    } else if (xs.status == Status.Failed) {
                        entry.failedReps = xs.reps;
                    }
                    let time = (w.sets[Math.min(w.sets.length - 1, i + 1)].date.getTime() - w.sets[Math.max(0, i - 1)].date.getTime()) / 2;
                    if (i == 0 || i == w.sets.length - 1) {
                        time *= 2;
                    }
                    if (time > 0) { // ongoing workout can mess this up
                        entry.time += time;
                    }
                }
            }
        }
        entry.frequency += 1;
    }

    let previousMaxReps = 0;
    for (let d of data) {
        if (d.failedReps != 0) {
            d.maxRepsSmooth = d.failedReps - 1;
            previousMaxReps = d.failedReps - 1;
        } else {
            if (d.maxReps > previousMaxReps) {
                d.maxRepsSmooth = d.maxReps;
                previousMaxReps = d.maxReps;
            }
        }
    }

    data[data.length - 1].maxRepsSmooth = previousMaxReps;

    const window = 3;
    const opacity = 0.1;

    if (chart) {
        chart.destroy();
    }
    const chartFactory = new ChartFactory("edit-exercise-canvas", data.map(row => row.date));
    chartFactory.addLine("Volume per week", smooth(data.map((row) => (row.maxReps != 0) ? (7 * row.totalReps / row.maxReps) : 0), window), "red");
    chartFactory.addLine("Minutes per week", smooth(data.map(row => 7 * row.time / (60 * 1000)), window), "blue");
    chartFactory.addLine("Max reps", data.map(row => row.maxRepsSmooth), "green");
    chartFactory.addLine("Volume per week (raw)", data.map((row) => (row.maxReps != 0) ? (7 * row.totalReps / row.maxReps) : 0), `rgba(255,0,0,${opacity}`, true);
    chartFactory.addLine("Minutes per week (raw)", data.map(row => 7 * row.time / (60 * 1000)), `rgba(0,0,255,${opacity}`, true);
    chartFactory.addLine("Max reps (raw)", data.map(row => row.maxReps), `rgba(0,255,0,0.2`, true);
    chart = chartFactory.create();

}

export async function editExercise(exercise: Exercise) {
    console.log(JSON.stringify(exercise));

    showLoading("Fetching workouts")
    await showChart(exercise);

    // Set the values of the inputs
    (document.getElementById("exercise-id") as HTMLDivElement).innerText = exercise.id;
    (document.getElementById("exercise-name") as HTMLInputElement).value = exercise.name;
    (document.getElementById("exercise-details") as HTMLInputElement).value = exercise.details;
    (document.getElementById("exercise-url") as HTMLInputElement).value = exercise.url;
    (document.getElementById("exercise-max-reps") as HTMLInputElement).value = String(exercise.state.maximumRepsPerSet);
    (document.getElementById("exercise-sets-per-week") as HTMLInputElement).value = exercise.getSetsPerWeek().toFixed(2);
    (document.getElementById("exercise-max-reps-per-workout") as HTMLInputElement).value = exercise.state.maximumRepsPerWorkout.toFixed(0);
    (document.getElementById("exercise-sets-pending") as HTMLInputElement).value = (exercise.getRepsPending(new Date()) / exercise.state.maximumRepsPerSet).toFixed(2);

    makeToolTip(document.getElementById("exercise-details-label") as HTMLInputElement, "Your description of the exercise.");
    makeToolTip(document.getElementById("exercise-max-reps-label") as HTMLInputElement, "How many repetitions can you perform in one set at most.");

    // Create checkboxes for primary muscles
    const primaryDiv = document.getElementById("exercise-primary-muscles")!;
    primaryDiv.innerHTML = ''; // Clear old checkboxes
    for (const [, muscle] of Object.entries(Muscle)) {
        primaryDiv.appendChild(createCheckBox(muscle, exercise.isPrimaryMuscle(muscle), undefined));
    }

    // Create checkboxes for secondary muscles
    const secondaryDiv = document.getElementById("exercise-secondary-muscles")!;
    secondaryDiv.innerHTML = ''; // Clear old checkboxes
    for (const [, muscle] of Object.entries(Muscle)) {
        secondaryDiv.appendChild(createCheckBox(muscle, exercise.isSecondaryMuscle(muscle), undefined));
    }

    // Create checkboxes for modalities
    const modalitiesDiv = document.getElementById("exercise-modalities")!;
    modalitiesDiv.innerHTML = ''; // Clear old checkboxes
    for (const [, modality] of Object.entries(Modality)) {
        if (modality == Modality.STRENGTH || modality == Modality.HYPERTROPHY) {
            continue;
        }
        modalitiesDiv.appendChild(createCheckBox(modality, exercise.isModality(modality), getModalityExplanation(modality)));
    }
    showPage('edit-exercise-page', null);

}

export async function saveExercise() {
    try {
        const exerciseId: DocumentId<Exercise> = (document.getElementById("exercise-id") as HTMLDivElement).innerText as DocumentId<Exercise>;
        let exercise: Exercise;
        let existing: boolean = true;
        try {
            exercise = await getDataStorage().getExercise(exerciseId);
        } catch (error: any) {
            exercise = new Exercise();
            existing = false;
        }

        const modalitiesDiv = document.getElementById("exercise-modalities")!;
        let enabledModalities: Modality[] = readCheckboxes(modalitiesDiv).map((x) => x as Modality);

        const name = (document.getElementById("exercise-name") as HTMLInputElement).value.trim();
        if (name == "") {
            throw new Error("name is empty");
        }
        exercise.name = name;

        exercise.details = (document.getElementById("exercise-details") as HTMLInputElement).value;

        exercise.url = (document.getElementById("exercise-url") as HTMLInputElement).value.trim();
        if (exercise.url != "" && !new URL(exercise.url).protocol) {
            throw new Error("url not valid");
        }

        exercise.state.maximumRepsPerSet = Number((document.getElementById("exercise-max-reps") as HTMLInputElement).value);
        if (isNaN(exercise.state.maximumRepsPerSet)) {
            throw new Error("reps per set is not a number");
        }

        const setsPerWeek = Number((document.getElementById("exercise-sets-per-week") as HTMLInputElement).value);
        if (isNaN(setsPerWeek)) {
            throw new Error("sets per week is not a number");
        }
        exercise.setSetsPerWeek(setsPerWeek);

        if (exercise.isModality(Modality.CONDITIONING) != enabledModalities.includes(Modality.CONDITIONING) || !existing) {
            if (enabledModalities.includes(Modality.CONDITIONING)) {
                exercise.state.maximumRepsPerWorkout = Math.floor(exercise.state.maximumRepsPerSet / 3);
            } else {
                exercise.state.maximumRepsPerWorkout = exercise.state.maximumRepsPerSet * 2;
            }
        }

        exercise.clearModalities();
        enabledModalities.forEach((m) => exercise.setModality(m, true));

        if (!exercise.isModality(Modality.SKILL) && !exercise.isModality(Modality.MOBILITY)) {
            if (exercise.state.maximumRepsPerSet > 5 && exercise.state.maximumRepsPerSet < 21) {
                exercise.setModality(Modality.HYPERTROPHY, true);
            }
            if (exercise.state.maximumRepsPerSet > 2 && exercise.state.maximumRepsPerSet < 9) {
                exercise.setModality(Modality.STRENGTH, true);
            }
        }

        const primaryDiv = document.getElementById("exercise-primary-muscles")!;
        exercise.setPrimaryMuscles(readCheckboxes(primaryDiv).map((x) => x as Muscle));
        const secondaryDiv = document.getElementById("exercise-secondary-muscles")!;
        exercise.setSecondaryMuscles(readCheckboxes(secondaryDiv).map((x) => x as Muscle));

        showLoading("Saving changes");

        if (existing) {
            await getDataStorage().updateExercise(exercise);
        } else {
            await getDataStorage().addExercise(exercise);
        }
        history.back();
    } catch (error: any) {
        console.log(error);
        showMessage((error as Error).message);
    }
}

export async function refreshMuscles() {
    const name = (document.getElementById("exercise-name") as HTMLInputElement).value.trim();
    if (name == "") {
        return;
    }
    const details = (document.getElementById("exercise-details") as HTMLInputElement).value.trim();

    showLoading("Looking up muscles for exercise");

    const r = await exerciseMuscles(name, details);

    const primaryDiv = document.getElementById("exercise-primary-muscles")!;
    primaryDiv.innerHTML = ''; // Clear old checkboxes
    for (const [, muscle] of Object.entries(Muscle)) {
        primaryDiv.appendChild(createCheckBox(muscle, r.primary.includes(muscle), undefined));
    }

    const secondaryDiv = document.getElementById("exercise-secondary-muscles")!;
    secondaryDiv.innerHTML = ''; // Clear old checkboxes
    for (const [, muscle] of Object.entries(Muscle)) {
        secondaryDiv.appendChild(createCheckBox(muscle, r.secondary.includes(muscle), undefined));
    }

    const modalitiesDiv = document.getElementById("exercise-modalities")!;
    modalitiesDiv.innerHTML = ''; // Clear old checkboxes
    let enabledModalities: Modality[] = readCheckboxes(modalitiesDiv).map((x) => x as Modality);
    for (const [, modality] of Object.entries(Modality)) {
        if (modality == Modality.ACTIVE || modality == Modality.CONDITIONING) {
            modalitiesDiv.appendChild(createCheckBox(modality, enabledModalities.includes(modality), getModalityExplanation(modality)));
        } else if (modality == Modality.STRENGTH) {
            // modalitiesDiv.appendChild(createCheckBox(modality, maxReps < 9  , getModalityExplanation(modality)));
        } else if (modality == Modality.HYPERTROPHY) {
            // modalitiesDiv.appendChild(createCheckBox(modality, maxReps > 5 && maxReps < 21, getModalityExplanation(modality)));
        } else {
            modalitiesDiv.appendChild(createCheckBox(modality, r.modalities.includes(modality), getModalityExplanation(modality)));
        }
    }

    showPage('edit-exercise-page', null);

}