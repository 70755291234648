import {initialize} from "./ui/tools";

window.onload = function() {
    const oldLog = console.log;
    console.log = function (...messages) {
        oldLog.apply(console, messages);
        document.getElementById('debug-data')!.innerHTML += messages.join(' ') + '<br>';
    };
    //noinspection

    window.addEventListener('error', function (event) {
        // Output the error message to the div
        document.getElementById('debug-data')!.innerHTML += 'Error: ' + event.message + '<br>';
    });

    window.addEventListener('unhandledrejection', function (event) {
        // Output the error message to the div
        document.getElementById('debug-data')!.innerHTML += 'Unhandled promise rejection: ' + event.reason + '<br>';
    });

    console.log( "Build date : " + process.env.BUILD_DATE );

    initialize();
};
