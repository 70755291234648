
// export function getEnums<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
//     return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
// }
//
export function getEnumIndex<O extends object, K extends keyof O = keyof O>(obj: O, entry:K ): number {
    let index = 0;
    for( const [,i] of Object.entries(obj)) {
        if( i == entry ) {
            return index;
        }
        ++index;
    }
    throw new Error( String(entry) + " not found in " + String(obj) );
}

export function assert(test: boolean) {
    if (!test) {
        throw new Error();
    }
}

export const DAY_IN_MILLIS = 1000*60*60*24;

export function listDifferences(oldObject: Record<string, any>, newObject: Record<string, any>): string {
    let differences: string[] = [];

    // Check properties in obj1
    for (let prop in oldObject) {
        if (oldObject[prop] != newObject[prop]) {
            differences.push(`${prop}: ${oldObject[prop]} -> ${newObject[prop]}`);
        }
    }

    return differences.join('\n');
}

enum TestEnum {
    keyA ="valueA",
    keyB ="valueB",
}

function testFunc(param:TestEnum) {
    console.log( typeof param + " " + param );
}

export function test() {
    for( const [,i] of Object.entries(TestEnum) ) {
        testFunc(i);
    }
}
