import {enableTooltips} from "./tools";

let historyStack: any[] = [];
let historyStackNames: any[] = [];

export function clearHistory() {
    historyStack = [];
    historyStackNames = [];
}

let pageChangeCallback: (() => void)[] = [];

export function addPageChangeCallback(f: (() => void)) {
    pageChangeCallback.push(f);
}

export function showMessage(message: string) {
    showPage("message-page", null, false );
    document.getElementById('message-text')!.innerText = message;
}

export function showLoading(message: string) {
    showPage("loading-page", null, false );
    document.getElementById('loading-text')!.innerText = message;
}

export function showPage(id: string, f: (() => void) | null, hide: boolean = true) {

    enableTooltips();

    if (pageChangeCallback) {
        for (let c of pageChangeCallback) {
            c();
        }
        pageChangeCallback = []
    }

    if (hide) {
        for (const page of Array.from(document.getElementsByClassName("page"))) {
            page.classList.add('hidden');
        }
    }
    document.getElementById(id)!.classList.remove('hidden');

    if ( historyStack.length != 0 && !historyStack[historyStack.length-1] ) {
        historyStack.pop();
        historyStackNames.pop();
    }

    historyStack.push(f);
    historyStackNames.push(id);

}

export function initializeController() {
    // noinspection JSUnusedLocalSymbols
    window.addEventListener('popstate', function (event) {
        // noinspection StatementWithEmptyBodyJS
        historyStack.pop();
        historyStackNames.pop();
        while (historyStack.length != 0 && !historyStack[historyStack.length - 1]) {
            historyStack.pop();
            historyStackNames.pop();
        }
        if (historyStack.length != 0) {
            history.pushState(null, "", document.URL);
            historyStackNames.pop();
            historyStack.pop()();
        } else {
            history.back();
        }
    });
    history.pushState(null, "", document.URL);
    document.getElementById('message-ok-btn')!.addEventListener('click', () => history.back());

}