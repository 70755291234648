import {DataStorage} from "./dataStorage";
import {assert, DAY_IN_MILLIS, listDifferences} from "./helper";
import {getDataStorage, isAnonymous, repsToString} from "../ui/tools";
import {sortExerciseSets, sortWorkout} from "./exerciseOrder";
import {FatigueProfile} from "./fatiguePredictor";
import {
    DocumentId,
    Exercise,
    ExerciseSet,
    ExerciseState,
    Modality,
    Muscle,
    Status,
    Workout,
    WorkoutTrait
} from "./exercises";

/**
 reported rir for skill and mobility work
 */
export const FAR_FROM_FAILURE_RIR = 5;
export const REPS_PER_DAY_INCREASE = 1.03; // results in ACWR of 1.5
export const REPS_PER_WORKOUT_INCREASE = 1.2;
export const WORKING_SET_RIR = 3;
export const TESTING_SET_RIR = 0;
export const MINIMUM_DAYS_BETWEEN_TESTS = 1.5; // at least 36h between attempts at a maximum.
export const MINIMUM_SETS_PER_WEEK = 2;
export const MINIMUM_REPS_FOR_MUSCLE_GROWTH = 6;
const DEBUG_WORKOUTS = false; // delete instead of save workout.

/*
what probability of success there is for succeeding with a working set for skills
 */
export const SKILL_SUCCESS_RATE = 0.75;
/*
how much (%) each set changes the estimate by
 */
export const SKILL_SUCCESS_CHANGE = 0.05;

export class WorkoutPlanner {

    private readonly data: DataStorage;

    constructor(data: DataStorage) {
        this.data = data;
    }

    debug() {
        const now: Date = new Date();
        const state = new ExerciseState(new Date(now.getTime() - DAY_IN_MILLIS * 0.8), 31.5, 21.7, 39.6, now, 3);
        const exercise = new Exercise("", "handstand", "", "", [Modality.ACTIVE, Modality.ISOMETRIC, Modality.SKILL], [], [], state);
        const set = new ExerciseSet("", 22, Status.Failed, false, 5, now);
        const workout = new Workout("", now, [set], [], false);
        this.updateStatePost(workout, exercise, 22, 0, 0, 22, 22);
    }

    private updateStatePost(workout: Workout, exercise: Exercise, assignedReps: number, completedReps: number, completedRepsMax: number, failedReps: number, failedRepsMax: number) {
        const oldState: ExerciseState = {...exercise.state};
        if (assignedReps == 0) {
            console.log("no reps in this workout, do nothing");
            return;
        }
        const maxRepsPerSetToRepsPerDayRatio = exercise.state.maximumRepsPerSet / exercise.state.repsPerDay;
        const maximumWorkoutDate = exercise.getMaximumWorkoutDate();
        const daysSinceWorkout = (workout.date.getTime() - exercise.state.workoutDate.getTime()) / DAY_IN_MILLIS;
        const progressDays = (Math.min(maximumWorkoutDate.getTime(), workout.date.getTime()) - exercise.state.workoutDate.getTime()) / DAY_IN_MILLIS;
        const completedRatio = (failedReps + completedReps) / exercise.getRepsPending(workout.date);
        const maxAllowedDaysSinceWorkout = (exercise.getTooLongBetweenWorkoutsDate().getTime() - exercise.state.workoutDate.getTime()) / DAY_IN_MILLIS;

        console.log("updating " + exercise.name + " " + daysSinceWorkout.toFixed(1) + " days elapsed, " + progressDays.toFixed(1) + " days of progress, " + (100 * completedRatio).toFixed(0) + "% completed");
        if (exercise.isModality(Modality.SKILL)) {
            let change;
            if (exercise.state.maximumRepsPerSet > 1) {
                const failChange = ((1 + SKILL_SUCCESS_CHANGE) ** -SKILL_SUCCESS_RATE) ** (1 / (1 - SKILL_SUCCESS_RATE));
                change = (1 + SKILL_SUCCESS_CHANGE) ** (completedReps / exercise.state.maximumRepsPerSet) * failChange ** (failedReps / exercise.state.maximumRepsPerSet);
            } else {
                const successRate = completedReps / (completedReps + failedReps);
                const weight = 1 - (1 - SKILL_SUCCESS_CHANGE) ** ((completedReps + failedReps) / exercise.state.maximumRepsPerSet);
                const updatedMaximumRepsPerSet = exercise.state.maximumRepsPerSet * (1 - weight) + weight * successRate / SKILL_SUCCESS_RATE;
                change = updatedMaximumRepsPerSet / exercise.state.maximumRepsPerSet;
            }
            exercise.state.maximumRepsPerSet *= change;
            if (exercise.state.maximumRepsPerSet > 1) {
                exercise.state.repsPerDay *= change;
            }
        } else if (exercise.isModality(Modality.MOBILITY)) {
            if (failedReps != 0) { // deload if fail
                exercise.state.repsPerDay /= REPS_PER_DAY_INCREASE ** 21
                exercise.state.maximumRepsPerSet /= REPS_PER_DAY_INCREASE ** 21;
                exercise.state.testRepsInReserve = TESTING_SET_RIR;
                exercise.state.maximumRepsPerWorkout = Math.ceil(exercise.state.maximumRepsPerSet / 3);
                exercise.state.testDate = workout.date;
            }
        } else {
            if (failedRepsMax > exercise.state.maximumRepsPerSet) { // failed to progress, allow volume to continue to accumulate.
                console.log("matched previous max, increase volume");
                exercise.state.testRepsInReserve = TESTING_SET_RIR;
                exercise.state.testDate = workout.date;
            } else if (failedReps != 0) { // failed to repeat the previous best performance, deload!
                console.log("failed to even match previous max, deload!");
                exercise.state.repsPerDay /= REPS_PER_DAY_INCREASE ** 21
                exercise.state.maximumRepsPerSet = Math.max(exercise.state.maximumRepsPerSet - 1, 1);
                exercise.state.testRepsInReserve = TESTING_SET_RIR;
                exercise.state.maximumRepsPerWorkout = Math.ceil(exercise.state.maximumRepsPerSet / 3);
                exercise.state.testDate = workout.date;
            } else if (completedRepsMax > exercise.state.maximumRepsPerSet) { // increased strength, lets continue with this volume and test again in 2 days.
                console.log("improved from previous max, aim to repeat testing cycle with same volume");
                exercise.state.repsPerDay /= REPS_PER_DAY_INCREASE ** 7;
                exercise.state.maximumRepsPerSet = completedRepsMax;
                exercise.state.testRepsInReserve = -1;
                exercise.state.testDate = workout.date;
            } else if (completedRepsMax >= exercise.getTestingSetReps()) { // successful sub maximal test..
                console.log("successfully passed a non PR test, decrease RIR");
                exercise.state.testRepsInReserve -= 1;
                exercise.state.testDate = workout.date;
            }
        }

        // rewind time relative to how much of our work was left undone.
        if (failedReps == 0 || exercise.isModality(Modality.SKILL)) {
            exercise.state.workoutDate = new Date(workout.date.getTime() - ((1 - completedRatio) * progressDays * DAY_IN_MILLIS));
        } else {
            exercise.state.workoutDate = workout.date;
        }
        // dont accumulate for mobility..
        if (exercise.isModality(Modality.MOBILITY)) {
            exercise.state.workoutDate = workout.date;
        }

        // with no training, decrease reps per day..
        exercise.state.repsPerDay /= REPS_PER_DAY_INCREASE ** daysSinceWorkout;
        // increase reps by twice the daily increase, to compensate for the by default reduction..
        exercise.state.repsPerDay *= REPS_PER_DAY_INCREASE ** (2 * progressDays * completedRatio);


        if (daysSinceWorkout > maxAllowedDaysSinceWorkout) {
            exercise.state.maximumRepsPerWorkout /= REPS_PER_WORKOUT_INCREASE ** ((daysSinceWorkout / maxAllowedDaysSinceWorkout) - 1);
        } else if (workout.date.getTime() > maximumWorkoutDate.getTime()) {
            exercise.state.maximumRepsPerWorkout *= REPS_PER_WORKOUT_INCREASE ** completedRatio;
        } else {
            exercise.state.maximumRepsPerWorkout /= REPS_PER_WORKOUT_INCREASE ** (1 / 2);
        }

        if (workout.isTrait(WorkoutTrait.Fatigued)) {
            exercise.state.repsPerDay /= REPS_PER_DAY_INCREASE ** daysSinceWorkout;
        }

        if (exercise.isModality(Modality.MOBILITY)) { // mobility always has same maximum reps to reps per day ratio.
            exercise.state.maximumRepsPerSet = exercise.state.repsPerDay * maxRepsPerSetToRepsPerDayRatio;
        }
        exercise.state.maximumRepsPerWorkout = Math.max(Math.ceil(exercise.state.maximumRepsPerSet / 3), exercise.state.maximumRepsPerWorkout);
        exercise.state.repsPerDay = Math.max(exercise.state.repsPerDay, exercise.state.maximumRepsPerSet * MINIMUM_SETS_PER_WEEK / 7); // at least two sets per week.

        // mark it as conditioning until we have the reps for a working set.
        exercise.setModality(Modality.CONDITIONING, exercise.state.maximumRepsPerWorkout < exercise.state.maximumRepsPerSet - WORKING_SET_RIR);

        if (!exercise.isModality(Modality.SKILL) && !exercise.isModality(Modality.MOBILITY)) {
            exercise.setModality(Modality.HYPERTROPHY, exercise.state.maximumRepsPerSet > 5 && exercise.state.maximumRepsPerSet < 21);
            exercise.setModality(Modality.STRENGTH, exercise.state.maximumRepsPerSet > 2 && exercise.state.maximumRepsPerSet < 9);
        }

        console.log(listDifferences(oldState, exercise.state));

    }

    private async calculateTimeToTesting(workout: Workout, exercise: Exercise): Promise<number> {
        let days = MINIMUM_DAYS_BETWEEN_TESTS;
        if (exercise.state.maximumRepsPerSet == 3) {
            days += 1;
        }
        if (exercise.state.maximumRepsPerSet == 2) {
            days += 2;
        }
        if (exercise.state.maximumRepsPerSet == 1) {
            days += 4;
        }
        if (workout.isTrait(WorkoutTrait.Fatigued)) {
            days += 4;
        }
        if (workout.isTrait(WorkoutTrait.External)) {
            days += 4;
        }
        if (workout.isTrait(WorkoutTrait.Tired)) {
            days += 4;
        }
        if (workout.isTrait(WorkoutTrait.Hurry)) {
            days += 4;
        }
        for (let set of workout.sets) {
            const setEx: Exercise = await this.data.getExercise(set.exerciseId);
            if (set.rir <= TESTING_SET_RIR && !setEx.isModality(Modality.MOBILITY) && !setEx.isModality(Modality.SKILL)) {
                for (let [, muscle] of Object.entries(Muscle)) {
                    days += 4 * (exercise.getMuscleWeight(muscle) * setEx.getMuscleWeight(muscle)) ** 2;
                }
            }
        }
        if (workout.isTrait(WorkoutTrait.Psyched)) {
            days /= 2;
        }
        return days;
    }

    private async generateSets(workout: Workout, exercise: Exercise): Promise<ExerciseSet[]> {
        let totalReps = Math.ceil(exercise.getRepsPending(workout.date));
        const daysSinceTest = (workout.date.getTime() - exercise.state.testDate.getTime()) / DAY_IN_MILLIS;
        let workingSetReps = exercise.getWorkingSetReps(workout.date);

        const daysSinceWorkout = (workout.date.getTime() - exercise.state.workoutDate.getTime()) / DAY_IN_MILLIS;
        const maxAllowedDaysSinceWorkout = (exercise.getTooLongBetweenWorkoutsDate().getTime() - exercise.state.workoutDate.getTime()) / DAY_IN_MILLIS;

        if (workout.isTrait(WorkoutTrait.Psyched)) {
            totalReps *= (workingSetReps + 1) / workingSetReps;
            workingSetReps += 1;
        }
        if (workout.isTrait(WorkoutTrait.External)) {
            totalReps *= Math.max(1, workingSetReps - 1) / workingSetReps;
            workingSetReps = Math.max(1, workingSetReps - 1);
        }
        if (workout.isTrait(WorkoutTrait.Fatigued)) {
            totalReps *= Math.max(1, workingSetReps - 1) / workingSetReps;
            workingSetReps = Math.max(1, workingSetReps - 1);
        }
        if (workout.isTrait(WorkoutTrait.Tired)) {
            totalReps *= Math.max(1, workingSetReps - 1) / workingSetReps;
            workingSetReps = Math.max(1, workingSetReps - 1);
        }

        let numberOfSets = Math.round(totalReps / workingSetReps);
        if (numberOfSets < 1) {
            return [];
        }

        let sets: ExerciseSet[] = []
        // ensure we are not conditioning anymore..
        if (daysSinceWorkout < maxAllowedDaysSinceWorkout && !exercise.isModality(Modality.CONDITIONING) && !exercise.isModality(Modality.SKILL) && !exercise.isModality(Modality.MOBILITY)) { // no testing sets if skill or while conditioning is still ongoing..
            if (daysSinceTest > await this.calculateTimeToTesting(workout, exercise)) {
                sets.push(new ExerciseSet(
                    exercise.id,
                    exercise.getTestingSetReps(),
                    Status.Pending,
                    false,
                    exercise.state.maximumRepsPerSet - exercise.getTestingSetReps(),
                    workout.date
                ));
                numberOfSets = Math.max(1, numberOfSets - 1);
            }
        }
        let rir;
        if (exercise.isModality(Modality.SKILL) || exercise.isModality(Modality.MOBILITY)) {
            if (exercise.isModality(Modality.STRENGTH)) {
                rir = 1;
            } else {
                rir = FAR_FROM_FAILURE_RIR;
            }
        } else {
            rir = exercise.state.maximumRepsPerSet - workingSetReps
        }
        for (let i = 0; i < numberOfSets; ++i) {
            const xs: ExerciseSet = new ExerciseSet(
                exercise.id,
                workingSetReps,
                Status.Pending,
                false,
                rir,
                workout.date
            );
            sets.push(xs);
        }

        if (sets.length > 1 && sets[0].reps > sets[1].reps) {
            [sets[0], sets[1]] = [sets[1], sets[0]];
        }

        if (exercise.isModality(Modality.UNILATERAL)) {
            sets = sets.flatMap((set) => [set.secondaryCopy(), set]);
        }
        return sets;
    }

    async endWorkout() {
        const workout = await this.data.getLatestWorkout();
        console.log("ending workout");
        if (workout.completed) {
            console.log("its already completed!");
            return; // already completed, no need to do anything.
        }
        let nothing = true;
        for (let xs of workout.sets) {
            if (xs.status == Status.Pending) {
                xs.status = Status.Skipped;
            }
            if (xs.status != Status.Skipped) {
                nothing = false;
            }
        }
        if (nothing) {
            console.log("deleting workout where nothing has been done");
            await this.data.removeWorkout(workout);
            return;
        }
        const exercises = new Map<DocumentId<Exercise>, Exercise>();
        for (let xs of workout.sets) {
            if (!exercises.has(xs.exerciseId)) {
                exercises.set(xs.exerciseId, await this.data.getExercise(xs.exerciseId));
            }
        }
        for (let exercise of exercises.values()) {
            let assignedReps = 0;
            let completedReps = 0;
            let completedRepsMax = 0;
            let failedReps = 0;
            let failedRepsMax = 0;
            for (let xs of workout.sets) {
                if (xs.exerciseId == exercise.id) {
                    if (xs.status == Status.Completed) {
                        completedReps += xs.reps;
                        completedRepsMax = Math.max(completedRepsMax, xs.reps);
                    }
                    if (xs.status == Status.Failed) {
                        failedReps += xs.reps;
                        failedRepsMax = Math.max(failedRepsMax, xs.reps);
                    }
                    assignedReps += xs.reps;
                }
            }
            if (exercise.isModality(Modality.UNILATERAL)) {
                assignedReps /= 2;
                completedReps /= 2;
                failedReps /= 2;
            }
            this.updateStatePost(workout, exercise, assignedReps, completedReps, completedRepsMax, failedReps, failedRepsMax);
            if (!DEBUG_WORKOUTS) {
                await this.data.updateExercise(exercise);
            }
        }

        workout.completed = true;
        if (!DEBUG_WORKOUTS) {
            await this.data.updateWorkout(workout);
        } else {
            await this.data.removeWorkout(workout);
        }
    }

    async updateExercise(index: number, status: Status) {
        const workout: Workout = await this.data.getLatestWorkout();
        assert(status != Status.Pending);
        if (workout.sets[index].status == status) {
            return;
        }
        workout.sets[index].status = status;
        workout.sets[index].date = new Date();
        const exercise = await this.data.getExercise(workout.sets[index].exerciseId);
        if (status == Status.Failed) {
            if (!exercise.isModality(Modality.SKILL)) {
                for (let xs2 of workout.sets) {
                    if (xs2.exerciseId == workout.sets[index].exerciseId && xs2.status == Status.Pending) {
                        xs2.status = Status.Skipped;
                    }
                }
            }
            // ensure we have same number of sets on the left and right side in the end.
            if (exercise.isModality(Modality.UNILATERAL)) {
                let primarySkipped = [];
                let secondarySkipped = [];
                for (let xs2 of workout.sets) {
                    if (xs2.exerciseId == workout.sets[index].exerciseId && xs2.reps == workout.sets[index].reps && xs2.status == Status.Skipped) {
                        if (xs2.secondary) {
                            secondarySkipped.push(xs2);
                        } else {
                            primarySkipped.push(xs2);
                        }
                    }
                }
                // mark earlier sets pending..
                secondarySkipped.reverse();
                primarySkipped.reverse();
                while (secondarySkipped.length > primarySkipped.length) {
                    secondarySkipped.pop()!.status = Status.Pending;
                }
                while (secondarySkipped.length < primarySkipped.length) {
                    primarySkipped.pop()!.status = Status.Pending;
                }
            }
        }

        let outOfOrder = false;
        let pending = []
        let other = []
        for (let i = 0; i < workout.sets.length; ++i) {
            if (i != index) {
                if (workout.sets[i].status == Status.Pending) {
                    pending.push(workout.sets[i]);
                    if (i < index) {
                        outOfOrder = true;
                    }
                } else {
                    other.push(workout.sets[i]);
                    if (i > index) {
                        outOfOrder = true;
                    }
                }
            }
        }
        if (outOfOrder) {
            console.log("exercises out of order, reordering...");
            workout.sets = await sortExerciseSets(other.concat([workout.sets[index]], pending), other.length + 1);
        }
        await this.data.updateWorkout(workout);
    }

    public async getRestDate(index: number): Promise<Date> {
        const workout: Workout = await this.data.getLatestWorkout();
        let fp: FatigueProfile = new FatigueProfile(1 / workout.getRelativeRest(), 1 / workout.getRelativeRest());
        let date = workout.date;
        for (let i = 0; i < workout.sets.length; ++i) {
            const xs = workout.sets[i];
            if (xs.status == Status.Skipped || xs.status == Status.Pending) {
                continue;
            }
            assert(xs.date.getTime() >= date.getTime());
            fp.addRest((xs.date.getTime() - date.getTime()) / 1000);
            date = xs.date;
            const exercise: Exercise = await this.data.getExercise(xs.exerciseId);
            fp.addSet(xs, exercise);
        }
        const rt = fp.getRest(workout.sets[index], await this.data.getExercise(workout.sets[index].exerciseId));
        return new Date(date.getTime() + rt * 1000);
    }

    // noinspection JSUnusedGlobalSymbols
    public async getWorkoutCompletedDate(): Promise<Date> {
        const workout: Workout = await this.data.getLatestWorkout();
        let fp: FatigueProfile = new FatigueProfile(1 / workout.getRelativeRest(), 1 / workout.getRelativeRest());
        let date = workout.date;
        for (let i = 0; i < workout.sets.length; ++i) {
            const xs = workout.sets[i];
            if (xs.status == Status.Skipped) {
                continue;
            }
            const exercise: Exercise = await this.data.getExercise(xs.exerciseId);
            if (xs.status == Status.Completed || xs.status == Status.Failed) {
                fp.addRest((xs.date.getTime() - date.getTime()) / 1000);
                date = xs.date;
            } else {
                const rest = fp.getRest(xs, exercise);
                date = new Date(date.getTime() + rest * 1000);
            }
            fp.addSet(xs, exercise);
        }
        return date;
    }

    async getWorkout(): Promise<Workout> {
        return this.data.getLatestWorkout();
    }


    async startWorkout(traits: WorkoutTrait[]): Promise<boolean> {
        console.log("creating a new workout");
        try {
            const now = new Date();
            const workout: Workout = new Workout("",
                now,
                [],
                traits,
                false,
            );
            const exercises: Exercise[] = await this.data.getExercises(true);
            console.log(exercises.map((e) => e.name));
            for (let exercise of exercises.sort((a: Exercise, b: Exercise) => a.getPriority(workout) - b.getPriority(workout))) {
                workout.sets.push(...await this.generateSets(workout, exercise));
            }

            // remove sets due to fatigue etc.
            console.log(workout.traits + " limit sets from " + +workout.sets.length + " to " + Math.ceil(workout.sets.length * workout.getVolume()))
            workout.sets = workout.sets.slice(0, Math.ceil(workout.sets.length * workout.getVolume()));

            if (workout.sets.length == 0) {
                return false;
            }
            workout.sets = await sortWorkout(workout.sets);
            await this.data.addWorkout(workout);
            // let ws = "workout:"
            // for (let xs of workout.sets) {
            //     const exercise: Exercise = await this.data.getExercise(xs.exerciseId);
            //     ws += "\n" + xs.reps + "x " + exercise.name + " " + exercise.state.maximumRepsPerSet;
            // }
            // console.log(ws);
            return true;
        } catch (error: any) {
            console.error(error.message);
            console.error(error.stack)
            return false;
        }
    }

    async getWorkoutSummary(workout: Workout): Promise<string[]> {
        assert(workout.completed)
        let volumeDone = 0;
        let setsDone = 0;
        let setsSkipped = 0;
        let descriptions = [];
        let startDate = workout.sets[0].date;
        let endDate = workout.sets[0].date;
        for (let xs of workout.sets) {
            if (xs.date.getTime() < startDate.getTime()) {
                startDate = xs.date;
            }
            if (xs.date.getTime() > endDate.getTime()) {
                endDate = xs.date;
            }
            const exercise = await getDataStorage().getExercise(xs.exerciseId);
            if (xs.status != Status.Skipped) {
                volumeDone += xs.getVolume() * exercise.getVolumeModifier(true, false);

                if (exercise.isModality(Modality.UNILATERAL)) {
                    setsDone += 0.5;
                } else {
                    ++setsDone;
                }
                const rirText = exercise.isModality(Modality.SKILL) ? " " : " (@" + xs.rir + " RIR) ";
                const repsText = repsToString(xs.reps, exercise.isModality(Modality.ISOMETRIC));
                if (!exercise.isModality(Modality.UNILATERAL)) {
                    if (isInteresting(xs, exercise)) {
                        descriptions.push(exercise.name + " " + repsText + rirText + xs.status + "!");
                    }
                } else if (!xs.secondary) {
                    let xs2 = findPair(workout, xs);
                    if (xs2.status == xs.status) {
                        if (isInteresting(xs, exercise)) {
                            descriptions.push(exercise.name + " " + repsText + rirText + "both sides " + xs.status + "!");
                        }
                    } else {
                        if (isInteresting(xs2, exercise)) {
                            descriptions.push(exercise.name + " " + repsText + rirText + "left side " + xs2.status + "!");
                        }
                        if (isInteresting(xs, exercise)) {
                            descriptions.push(exercise.name + " " + repsText + rirText + "right side " + xs.status + "!");
                        }

                    }
                }
            } else {
                if (exercise.isModality(Modality.UNILATERAL)) {
                    setsSkipped += 0.5;
                } else {
                    ++setsSkipped;
                }
            }
        }
        const minutes = (1000 * 60 + endDate.getTime() - startDate.getTime()) / (1000 * 60);


        descriptions = [volumeDone.toFixed(1) + " sets done for muscle growth."].concat(descriptions);
        if (setsSkipped != 0) {
            descriptions = [setsSkipped + " sets skipped."].concat(descriptions);
        }

        if (workout.traits.length != 0) {
            descriptions = [workout.traits.join(" ,")].concat(descriptions);
        }

        if (minutes < 2) {
            descriptions = [setsDone + " sets done."].concat(descriptions);
        } else {
            descriptions = [setsDone + " sets done in " + minutes.toFixed(0) + " minutes!!"].concat(descriptions);
        }
        return descriptions;
    }

    public async getNextWorkoutDate(): Promise<Date[]> {
        let min = new Date(new Date().getTime() + DAY_IN_MILLIS * 30);
        let max = new Date(new Date().getTime() - DAY_IN_MILLIS * 30);
        for (let exercise of await this.data.getExercises(true)) {
            const workout = exercise.getMaximumWorkoutDate();
            if (workout.getTime() < min.getTime()) {
                min = workout;
            }
            if (workout.getTime() > max.getTime()) {
                max = workout;
            }
        }
        return [min, max]
    }

    public async getWarnings(): Promise<string[]> {
        let warnings = [];
        for (let exercise of await getDataStorage().getExercises(true)) {
            warnings.push(...exercise.getWarnings(true));
        }
        if (isAnonymous) {
            warnings.push("Not signed in, your exercise data may be lost.");
        }
        const [min, max] = await this.getNextWorkoutDate();
        if (new Date().getTime() > max.getTime()) {
            warnings.push("Overdue for a workout!");
        } else if (new Date().getTime() > min.getTime()) {
            warnings.push("Ready for a workout!");
        }
        return warnings;
    }

}

function isInteresting(set: ExerciseSet, exercise: Exercise): boolean {
    if (exercise.isModality(Modality.MOBILITY)) {
        if (set.status == Status.Failed) {
            return true;
        }
    } else if (exercise.isModality(Modality.SKILL)) {
        if (set.status == Status.Completed && set.reps == 1) {
            return true;
        }
        if (set.status == Status.Failed && set.reps > 1) {
            return true;
        }
    } else {
        if (set.status == Status.Failed) {
            return true;
        }
        if (set.rir <= 0 && set.reps != 1) {
            return true;
        }
        // if (set.rir < WORKING_SET_RIR && set.reps != 1 ) {
        //     return true;
        // }
    }
    return false;
}

function findPair(workout: Workout, set: ExerciseSet) {
    for (let s of workout.sets) {
        if (s.exerciseId == set.exerciseId && s.reps == set.reps && s.secondary != set.secondary) {
            return s;
        }
    }
    // throw new Error("couldn't find matching set for " + JSON.stringify(set));
    return set; // hack to fix old code..
}

// function removeLast(array: ExerciseSet[], f: (x: ExerciseSet) => boolean, percentage: number) {
//     const count = array.reduce((total, obj) => f(obj) ? total + 1 : total, 0);
//     let toRemove = Math.ceil(count * percentage);
//     return [...array].reverse().filter(item => {
//         if (toRemove > 0 && f(item)) {
//             toRemove -= 1;
//             return true;
//         }
//         return false;
//     }).reverse();
// }