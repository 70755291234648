/*
epley[reps_]:=1+reps/30
s={
(*10.2165/11315230-000000000-00000*)
{0.5,0,14.9,4.9},
{0.5,0,15.6,10.1},
{1,0,11.9,6},
{1,0,7.4,4.4},
{1,0,7.8,5.9},
{1,0,9.3,3.3},
{1,0,29.8,10.0},
{1,0,14.6,5.9},
{1,0,15.4,10.6},
{2,0,7.7,5.7},
{2,0,9.1,5.1},
{2,0,29.9,14.8},
{2,0,14.6,8.6},
{2,0,15.4,12.5},
{3,0,11.5,8},
{3,0,8,6.6},
{3,0,9.1,5.9},
{3,0,30.4,18.2},
{5,0,11.5,10},
{5,0,7.6,6.5},
{5,0,8,7.6},
(*10.1186/s40798-023-00554-y*)
{4,0,13.9,10.8},
{4,1,12.8,10.8},
{4,3,10.3,9.3},
(*10.1519/JSC.0000000000002915*)
{2,0,11.6,6.3},
{2,4,7.6,6.2}
};
s2=Map[{#[[1]],#[[2]],#[[3]],epley[#[[4]]]/epley[#[[3]]]}&,s];
(*model=NonlinearModelFit[s2,LogisticSigmoid[a*(rest+rir)+c*reps^d],{a,c,d},{rest,rir,reps}];*)
model=NonlinearModelFit[s2,LogisticSigmoid[a*rest + b*rir + c*reps+d],{a,b,c,d},{rest,rir,reps}];
r=Map[{#[[1]],#[[2]],#[[3]],#[[4]],model[#[[1]],#[[2]],#[[3]]],#[[4]]-model[#[[1]],#[[2]],#[[3]]]}&,s2];
TableForm[r]
f=Normal[model]
model["RSquared"]
model["AdjustedRSquared"]
Sqrt[Mean[model["FitResiduals"]^2]]
Simplify[rest/.First[Solve[strength==f,rest]]]
 */

import {Exercise, ExerciseSet, Modality, Muscle, Status} from "./exercises";

function oneRM(reps: number): number {
    return 1 + reps / 40;
}

const LOCAL_FATIGUE_MULTIPLIER = 40;
const GLOBAL_FATIGUE_MULTIPLIER = 1 / 400;

export class FatigueProfile {

    public fatigue: number[];

    constructor(public localRecovery: number, public systemicRecovery: number) {
        this.fatigue = Array(Object.entries(Muscle).length * 2 + 1).fill(0);
    }

    // noinspection JSUnusedLocalSymbols
    private getLocalFatigue(set: ExerciseSet, exercise: Exercise): number {
        const rir = (set.status == Status.Failed) ? 0 : (set.rir + 0.5);
        const localFatigue = set.reps / (set.reps + rir);
        return localFatigue * LOCAL_FATIGUE_MULTIPLIER / this.localRecovery;
    }

    private getGlobalFatigue(set: ExerciseSet, exercise: Exercise): number {
        // calculate total work done (oxygen used) during the set
        let globalFatigue = exercise.getMuscleVolume() * (set.reps / oneRM(set.reps + set.rir + 0.5));

        if (exercise.isModality(Modality.UNILATERAL)) {
            globalFatigue *= 2 / 3;
        }
        if (exercise.isModality(Modality.MOBILITY) || exercise.isModality(Modality.SKILL)) {
            globalFatigue *= 1 / 2;
        }
        return globalFatigue * GLOBAL_FATIGUE_MULTIPLIER / this.systemicRecovery;
    }

    addRest(restInSeconds: number) {
        this.fatigue = this.fatigue.map((x) => Math.max(0, x - restInSeconds));
    }

    addSet(set: ExerciseSet, exercise: Exercise) {
        const localFatigue = this.getLocalFatigue(set, exercise);
        let index = 0;
        for (const [, muscle] of Object.entries(Muscle)) {
            if (!exercise.isModality(Modality.UNILATERAL) || set.secondary) {
                this.fatigue[index] += localFatigue * exercise.getMuscleWeight(muscle);
            }
            index += 1;
            if (!exercise.isModality(Modality.UNILATERAL) || !set.secondary) {
                this.fatigue[index] += localFatigue * exercise.getMuscleWeight(muscle);
            }
            index += 1;
        }
        this.fatigue[index] += this.getGlobalFatigue(set, exercise);
    }

    getRest(set: ExerciseSet, exercise: Exercise) {
        let f = new FatigueProfile(this.localRecovery, this.systemicRecovery);
        f.addSet(set, exercise);
        let total = 0;

        console.log("calculating rest for " + exercise.name );
        for (const [index, [, muscle]] of Object.entries(Muscle).entries()) {
            const fLeft = Math.sqrt(f.fatigue[index * 2] * this.fatigue[index * 2]);
            const fRight = Math.sqrt(f.fatigue[index * 2 + 1] * this.fatigue[index * 2 + 1]);
            if (fLeft != 0 || fRight != 0 ) {
                console.log(muscle + " : " + fLeft.toFixed() + " " + fRight.toFixed());
            }
        }
        console.log("cardio : " + Math.sqrt(f.fatigue[f.fatigue.length - 1] * this.fatigue[this.fatigue.length - 1]).toFixed());

        for (let i = 0; i < this.fatigue.length; ++i) {
            total += f.fatigue[i] * this.fatigue[i];
        }
        total = Math.sqrt(total);
        // more rest time for skills..
        if (exercise.isModality(Modality.SKILL)) {
            total *= 1.25;
        }
        return total;
    }

    copy(): FatigueProfile {
        const fp = new FatigueProfile(this.localRecovery, this.systemicRecovery);
        fp.fatigue = {...this.fatigue};
        return fp;
    }

}

export function fatigueTest() {
    const pullupMuscles = [
        Muscle.POSTERIOR_DELTOIDS,
        Muscle.TRAPEZIUS,
        Muscle.LATISSIMUS_DORSI,
        Muscle.BICEPS_BRACHII,
        Muscle.FOREARM_MUSCLES
    ];
    const squatMuscles = [
        Muscle.QUADRICEPS,
        Muscle.HAMSTRINGS,
        Muscle.GLUTEAL_MUSCLES,
        Muscle.CALVES,
        Muscle.HIP_ABDUCTORS,
    ];
    const pullupExercise = new Exercise("", "pull up", "", "", [], pullupMuscles, []);
    const squatExercise = new Exercise("", "squat", "", "", [], squatMuscles, []);
    for (let same of [true, false]) {
        for (let rir of [0, 3, 6]) {
            for (let reps of [1, 5, 15]) {
                let f = new FatigueProfile(1, 1);
                f.addSet(
                    new ExerciseSet("", reps, Status.Completed, false, rir, new Date()),
                    pullupExercise
                )
                let r = f.getRest(
                    new ExerciseSet("", reps, Status.Completed, false, rir, new Date()),
                    (same ? pullupExercise : squatExercise)
                )
                console.log(((same) ? "same" : "different") + " " + rir + " rir " + reps + " reps " + Math.round(r));
            }
        }
    }
}

