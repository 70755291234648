import {
    beep,
    createCheckBox,
    createExerciseDiv,
    getDataStorage,
    getWorkoutPlanner,
    readCheckboxes,
    repsToString,
    workoutInProgress,
    CountdownTimer,
} from "./tools";
import {showPage, addPageChangeCallback, showLoading} from "./controller";
import {
    Exercise,
    ExerciseSet,
    getWorkoutTraitExplanation,
    Modality,
    Status,
    Workout,
    WorkoutTrait
} from "../logic/exercises";

async function readyForExercise() {
    const workout: Workout = await getWorkoutPlanner().getWorkout();
    const exerciseSet: ExerciseSet = workout.sets[selectedExerciseIndex];
    const exercise: Exercise = await getDataStorage().getExercise(exerciseSet.exerciseId);
    let text = exercise.name + " for " + repsToString(exerciseSet.reps, exercise.isModality(Modality.ISOMETRIC));
    if (exercise.isModality(Modality.UNILATERAL)) {
        if (exerciseSet.secondary) {
            text += " on the left side.";
        } else {
            text += " on the right side.";
        }
    }
    if ('speechSynthesis' in window) {
        let utterance = new SpeechSynthesisUtterance(text);
        utterance.volume = 1;
        speechSynthesis.speak(utterance);
    } else {
        beep(1, 1000, 1, 0, 'sine');
    }
    let instructionsElement = document.getElementById('workout-instructions');
    instructionsElement!.innerText = "Ready for the exercise!";
    if (exercise.isModality(Modality.ISOMETRIC)) {
        if( !isometricTimer ) {
            isometricTimer = new CountdownTimer();
        }
        // noinspection JSUnusedLocalSymbols
        isometricTimer.start(60 * 60 * 6, (minutes: number, seconds: number) => {
            if (('speechSynthesis' in window) && speechSynthesis.speaking) {
                return; // wait until speech is done before starting metronome.
            }
            beep(0.05, 440, 0.25, 0, 'square');
        });
    }
}

async function showWorkout() {
    showPage('workout-page',null);
    addPageChangeCallback(() => {
        exerciseTimer?.cancel();
        isometricTimer?.cancel()
    });
    await displayCurrentExercise(await getNextExerciseIndex());
}

export async function createWorkout() {
    showLoading( "Creating workout");
    if (await workoutInProgress()) {
        throw new Error("workout in progress when asked to create a workout!");
    }
    const traitsDiv = document.getElementById('workout-start-traits')!;
    const traits: WorkoutTrait[] = readCheckboxes(traitsDiv).map((x) => x as WorkoutTrait);
    if (!await getWorkoutPlanner().startWorkout(traits)) {
        if ((await getDataStorage().getExercises(true)).length == 0) {
            if ((await getDataStorage().getExercises(false)).length == 0) {
                showWorkoutProblem("You have no exercises, please add an exercise first.");
            } else {
                showWorkoutProblem("You have no active exercises, please mark an exercise active first.");
            }
        } else {
            showWorkoutProblem("Not ready for a new workout yet, recover for now.");
        }
    } else {
        await showWorkout();
    }
}

export async function startWorkout() {
    if (await workoutInProgress()) {
        await showWorkout();
    } else {
        const traitsDiv = document.getElementById('workout-start-traits')!;
        traitsDiv.innerHTML = "";
        for (const [,trait] of Object.entries(WorkoutTrait)) {
            traitsDiv.appendChild(createCheckBox(trait, false, getWorkoutTraitExplanation(trait)));
        }
        showPage('workout-start-page',null);
    }
}

let exerciseTimer:CountdownTimer|null = null;
let isometricTimer:CountdownTimer|null = null;

export async function nextExercise(result: Status) {
    await getWorkoutPlanner().updateExercise(selectedExerciseIndex, result);
    try {
        const newExerciseIndex = await getNextExerciseIndex();
        await displayCurrentExercise(newExerciseIndex);
    } catch (error: any) {
        console.log("failed to get next exercise, ending workout.");
        await getWorkoutPlanner().endWorkout();
        await showWorkoutCompleted();
    }
}

async function getNextExerciseIndex() {
    const workout: Workout = await getWorkoutPlanner().getWorkout();
    for (let i = 0; i < workout.sets.length; ++i) {
        if (workout.sets[i].status == Status.Pending) {
            return i;
        }
    }
    throw new Error("no pending exercises for " + workout.id);
}

let selectedExerciseIndex = -1;

async function displayCurrentExercise(selectedIndex: number) {
    exerciseTimer?.cancel();
    isometricTimer?.cancel();
    selectedExerciseIndex = selectedIndex;
    let exerciseElement = document.getElementById('current-exercises')!;
    const workout: Workout = await getWorkoutPlanner().getWorkout();
    document.getElementById('workout-instructions')!.innerText = '';
    exerciseElement.innerHTML = '';
    let doneCount = 0;
    let pendingCount = 0;
    for (let i = 0; i < workout.sets.length; ++i) {
        const exerciseSet: ExerciseSet = workout.sets[i];
        const exercise: Exercise = await getDataStorage().getExercise(exerciseSet.exerciseId);

        if (exerciseSet.status == Status.Completed || exerciseSet.status == Status.Failed) {
            if (exercise.isModality(Modality.UNILATERAL)) {
                doneCount += 0.5;
            } else {
                doneCount += 1;
            }
        }
        if (exerciseSet.status == Status.Pending) {
            if (exercise.isModality(Modality.UNILATERAL)) {
                pendingCount += 0.5;
            } else {
                pendingCount += 1;
            }
        }

        let exerciseDiv = createExerciseDiv(exerciseSet, exercise, false, true, false);
        if (i == selectedIndex) {
            exerciseDiv.classList.add("current-exercise");
        } else if (exerciseSet.status == Status.Skipped ) {
            exerciseDiv.classList.add("skipped-exercise");
        } else if (exerciseSet.status == Status.Failed ) {
            exerciseDiv.classList.add("failed-exercise");
        } else if (exerciseSet.status == Status.Completed ) {
            exerciseDiv.classList.add("completed-exercise");
        } else {
            exerciseDiv.classList.add("pending-exercise");
        }
        exerciseDiv.addEventListener('click', () => displayCurrentExercise(i))
        exerciseElement.appendChild(exerciseDiv);
    }

    document.getElementById('workout-state')!.innerText = "(" + doneCount + "/" + (pendingCount + doneCount) + ")";

    const restDate: Date = await getWorkoutPlanner().getRestDate(selectedIndex);
    const restTimeMs = restDate.getTime() - new Date().getTime();
    if (restTimeMs > 0) {
        if( !exerciseTimer ) {
            exerciseTimer = new CountdownTimer();
        }
        exerciseTimer.start(restTimeMs / 1000, (minutes, seconds) => {
            let instructionsElement = document.getElementById('workout-instructions');
            if (minutes == 0 && seconds == 0) {
                readyForExercise();
            } else {
                instructionsElement!.innerText = `${minutes}:${seconds.toString().padStart(2, '0')} of rest remaining.`;
            }
        });
    } else {
        readyForExercise().then();
    }

}

function showWorkoutProblem(summary: string) {
    document.getElementById('workout-completed-summary')!.innerHTML = summary;
    showPage('workout-completed-page',null,);
}

export async function showWorkoutCompleted() {
    showLoading( "Completing workout");
    const element = document.getElementById('workout-completed-summary')!;
    element.innerHTML = "";

    const tn = document.createElement("h2");
    tn.innerText = "Workout completed!";
    element.appendChild(tn);

    const ul = document.createElement("ul");
    for (let d of await getWorkoutPlanner().getWorkoutSummary(await getWorkoutPlanner().getWorkout())) {
        let li = document.createElement('li');
        li.innerText = d;
        ul.appendChild(li);
    }
    element.appendChild(ul);
    showPage('workout-completed-page',null);
}

