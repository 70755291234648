import {
    addDoc,
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentData,
    DocumentReference,
    Firestore,
    FirestoreDataConverter,
    getDoc,
    getDocFromCache,
    getDocFromServer,
    getDocs,
    getDocsFromCache,
    getDocsFromServer,
    limit,
    orderBy,
    query,
    QueryDocumentSnapshot,
    QuerySnapshot,
    updateDoc
} from "firebase/firestore";
import {
    DocumentId,
    Exercise,
    ExerciseSet,
    ExerciseState,
    Modality, Muscle,
    Status,
    UserState,
    Workout,
    WorkoutTrait
} from "./exercises";

const exerciseConverter: FirestoreDataConverter<Exercise> = {
    toFirestore(exercise: Exercise): DocumentData {
        return {
            name: exercise.name,
            details: exercise.details,
            modalities: exercise.getModalities().map( (m) => m as string ),
            primary: exercise.getPrimaryMuscles().map( (m) => m as string ),
            secondary: exercise.getSecondaryMuscles().map( (m) => m as string ),
            state: {
                workoutDate: exercise.state.workoutDate,
                repsPerDay: exercise.state.repsPerDay,
                maximumRepsPerSet: exercise.state.maximumRepsPerSet,
                maximumRepsPerWorkout: exercise.state.maximumRepsPerWorkout,
                testDate: exercise.state.testDate,
                testRepsInReserve: exercise.state.testRepsInReserve
            }
        };
    },

    fromFirestore(snapshot: QueryDocumentSnapshot): Exercise {
        const data = snapshot.data();
        return new Exercise(
            snapshot.id,
            data.name,
            data.details,
            data.url?data.url:"",
            Object.values(Modality).filter((modality)=>data.modalities.includes(modality as string)),
            Object.values(Muscle).filter((muscle)=>data.primary.includes(muscle as string)),
            Object.values(Muscle).filter((muscle)=>data.secondary.includes(muscle as string)),
            new ExerciseState(
                data.state.workoutDate.toDate(),
                data.state.repsPerDay,
                data.state.maximumRepsPerSet,
                data.state.maximumRepsPerWorkout,
                data.state.testDate.toDate(),
                data.state.testRepsInReserve
            )
        );
    },
};


const workoutConverter: FirestoreDataConverter<Workout> = {
    toFirestore(workout: Workout): DocumentData {
        return {
            date: workout.date,
            sets: workout.sets.map((set: any) => ({
                exerciseId: set.exerciseId,
                reps: set.reps,
                status: set.status,
                secondary: set.secondary,
                rir: set.rir,
                date: set.date
            })),
            traits: workout.traits.map(m => m as string),
            completed: workout.completed
        };
    },

    fromFirestore(snapshot: QueryDocumentSnapshot): Workout {
        const data = snapshot.data();
        return new Workout(
            snapshot.id,
            data.date.toDate(),
            data.sets.map((set: any) => new ExerciseSet(set.exerciseId, set.reps, set.status as Status, set.secondary, set.rir, set.date.toDate())),
            (data.traits as string[]).map(m => m as WorkoutTrait),
            data.completed
        );
    },
};

const userStateConverter: FirestoreDataConverter<UserState> = {
    toFirestore(userState: UserState): DocumentData {
        return userState;
    },

    fromFirestore(snapshot: QueryDocumentSnapshot): UserState {
        const data = snapshot.data();
        const userState = new UserState();
        userState.localRecovery = data.localRecovery;
        userState.cardioRecovery = data.cardioRecovery;
        userState.workoutDates = data.workoutDates.map((date:any)=>date.toDate());
        return userState;
    },
};

export class DataStorage {

    private readonly exercises: CollectionReference<Exercise>;
    private readonly workouts: CollectionReference<Workout>;
    private readonly userState: DocumentReference<UserState>;

    constructor(firestore: Firestore, userId: string) {
        this.exercises = collection(firestore, `users/${userId}/exercises`).withConverter(exerciseConverter);
        this.workouts = collection(firestore, `users/${userId}/workouts`).withConverter(workoutConverter);
        this.userState = doc(firestore, `users/${userId}/other/userState`).withConverter(userStateConverter);

        // getDoc(this.userState).then((docSnapshot: DocumentSnapshot<UserState>)=> {
        //     if( !docSnapshot.exists() ) {
        //         const us:UserState=new UserState();
        //         setDoc(this.userState, us).catch((reason)=>console.log(reason));
        //     }
        // });


        getDocsFromServer(this.exercises).then( (snapshot)=> {
            console.log(snapshot.size + " exercises fetched from server.");
            if( snapshot.size == 0 ) {
                console.log( "adding default exercises." );
                this.initDefaultExercises().then();
            }
        });

    }

    async initDefaultExercises() {
        const q = query(this.exercises, limit(1));
        getDocs(q).then((q) => {
            if (q.empty) {
                // add default exercises..
                const push = new Exercise();
                push.name = "Push-up"
                push.details = "Chest to ground";
                push.url = "https://www.instagram.com/reel/CsW7ipPg55o";
                push.setModality(Modality.CONDITIONING, true);
                push.setModality(Modality.ACTIVE, true);
                push.state.maximumRepsPerSet = 7;
                push.state.repsPerDay = 3.5;
                push.state.maximumRepsPerWorkout = Math.floor(7 / 3);
                this.addExercise(push).catch((reason)=>console.log(reason));

                const pull = new Exercise();
                pull.name = "Inverted row"
                pull.details = "Under a sturdy table, fail when elbow cant bend 90°";
                pull.url = "https://www.instagram.com/p/B-jxmq1gfTO"
                pull.setModality(Modality.CONDITIONING,true);
                pull.setModality(Modality.ACTIVE,true);
                pull.state.maximumRepsPerSet = 7;
                pull.state.repsPerDay = 3.5;
                pull.state.maximumRepsPerWorkout = Math.floor(7 / 3);
                this.addExercise(pull).catch((reason)=>console.log(reason));

                const leg = new Exercise();
                leg.name = "Split squat"
                leg.details = "Focus on large range of motion";
                leg.url = "https://www.instagram.com/tv/CTfQ9dQIYkZ";
                leg.setModality(Modality.UNILATERAL,true);
                leg.setModality(Modality.CONDITIONING,true);
                leg.setModality(Modality.ACTIVE,true);
                leg.state.maximumRepsPerSet = 14;
                leg.state.repsPerDay = 7;
                leg.state.maximumRepsPerWorkout = Math.floor(14 / 3);
                this.addExercise(leg).catch((reason)=>console.log(reason));
            }
        });

    }

    async getExercises(active: boolean): Promise<Exercise[]> {
        let snapshot = await getDocsFromCache(this.exercises);
        return snapshot.docs.map(doc => doc.data()).filter(exercise=>exercise.isModality(Modality.ACTIVE) == active );
    }

    public workoutsUptoDate:boolean = false;

    async getWorkouts(): Promise<Workout[]> {
        let snapshot:QuerySnapshot<Workout>;
        if( !this.workoutsUptoDate ) {
            try {
                const time = new Date().getTime();
                snapshot = await getDocsFromServer(this.workouts);
                console.log( snapshot.size + " workouts fetched from server in " + ( new Date().getTime() - time ) + " ms" );
                this.workoutsUptoDate = true;
            } catch(_) {
                snapshot = await getDocsFromCache(this.workouts);
            }
        } else {
            snapshot = await getDocsFromCache(this.workouts);
        }
        return snapshot.docs.map((doc) => doc.data()).sort((a:Workout,b:Workout)=>b.date.getTime()-a.date.getTime());
    }

// noinspection JSUnusedGlobalSymbols
    async getUserState(): Promise<UserState> {
        const docSnapshot = await getDoc(this.userState);
        return docSnapshot.data() as UserState;
    }

// noinspection JSUnusedGlobalSymbols
    async updateUserState(userState: UserState) {
        await updateDoc(this.userState, userStateConverter.toFirestore(userState));
    }

    async getLatestWorkout(): Promise<Workout> {
        if (!this.cachedLatestWorkout) {
            const q = query(this.workouts, orderBy("date", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
                const workout = querySnapshot.docs[0].data();
                this.cachedLatestWorkout = workout.id;
                this.cachedLatestWorkoutDate = workout.date;
                return workout;
            }
        }
        if (this.cachedLatestWorkout) {
            const docRef = doc(this.workouts, this.cachedLatestWorkout);
            try {
                const docSnapshot = await getDocFromCache(docRef);
                if (docSnapshot.exists()) {
                    return docSnapshot.data();
                }
            } catch (error: any) {
                const docSnapshot = await getDocFromServer(docRef);
                if (docSnapshot.exists()) {
                    return docSnapshot.data();
                }
            }
            throw new Error(`No workout found with id: ${this.cachedLatestWorkout}`);
        }
        throw new Error("no workout found");
    }

    private cachedLatestWorkout: DocumentId<Workout> | null = null;
    private cachedLatestWorkoutDate: Date | null = null;

    async addWorkout(workout: Workout) {
        const docRef = await addDoc(this.workouts, workoutConverter.toFirestore(workout));
        workout.id = docRef.id as DocumentId<Workout>;
        if (this.cachedLatestWorkoutDate && workout.date.getTime() > this.cachedLatestWorkoutDate.getTime()) {
            this.cachedLatestWorkout = workout.id;
            this.cachedLatestWorkoutDate = workout.date;
        }
    }

    async updateWorkout(workout: Workout) {
        if (this.cachedLatestWorkout == workout.id && this.cachedLatestWorkoutDate != workout.date) {
            this.cachedLatestWorkoutDate = null;
            this.cachedLatestWorkout = null;
        }
        const docRef = doc(this.workouts, workout.id);
        await updateDoc(docRef, workoutConverter.toFirestore(workout));
        if (this.cachedLatestWorkoutDate && workout.date.getTime() > this.cachedLatestWorkoutDate.getTime()) {
            this.cachedLatestWorkout = workout.id;
            this.cachedLatestWorkoutDate = workout.date;
        }
    }

    async getExercise(documentId: DocumentId<Exercise>): Promise<Exercise> {
        const docRef = doc(this.exercises, documentId);
        try {
            const docSnapshot = await getDocFromCache(docRef);
            if (docSnapshot.exists()) {
                return docSnapshot.data();
            }
        } catch (error: any) {
            const docSnapshot = await getDocFromServer(docRef);
            if (docSnapshot.exists()) {
                return docSnapshot.data();
            }
        }
        throw new Error(`No exercise found with id: ${documentId}`);
    }

    async updateExercise(exercise: Exercise) {
        const docRef = doc(this.exercises, exercise.id);
        await updateDoc(docRef, exerciseConverter.toFirestore(exercise));
    }

    async addExercise(exercise: Exercise) {
        const docRef = await addDoc(this.exercises, exercise);
        exercise.id = docRef.id as DocumentId<Exercise>;
    }

    async removeWorkout(workout: Workout) {
        const docRef = doc(this.workouts, workout.id);
        await deleteDoc(docRef);

    }
}

