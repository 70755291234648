import {getWorkoutPlanner, workoutInProgress} from "./tools";
import {addPageChangeCallback, clearHistory, showPage} from "./controller";

async function updatePreparedness() {
    const element = document.getElementById('prepared')!;
    let warningElements = document.createElement('ul');
    for( let w of await getWorkoutPlanner().getWarnings() ) {
        let warningElement = document.createElement('li');
        if( w.includes("Ready for")) {
            warningElement.classList.add("encourage");
        } else {
            warningElement.classList.add("warning");
        }
        warningElement.textContent = w;
        warningElements.appendChild(warningElement);
    }
    element.innerHTML = "";
    element.appendChild(warningElements);
}

let timerId: NodeJS.Timeout | null = null;


export async function showMainPage() {
    if (await workoutInProgress()) {
        document.getElementById('start-workout-btn')!.innerText = "Continue workout";
        document.getElementById('prepared')!.innerText = "Workout in progress";
    } else {
        document.getElementById('start-workout-btn')!.innerText = "Start workout";
        updatePreparedness().then();
    }
    clearHistory();
    showPage('main-page',showMainPage);
    addPageChangeCallback(() => {
        clearTimeout(timerId!);
    });
    timerId = setInterval(()=>{
        updatePreparedness().then();
    },10*60*1000);
}

