import {FirebaseApp} from "@firebase/app";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";
import {Firestore, getFirestore, initializeFirestore, persistentLocalCache} from "firebase/firestore";
import {Auth, getRedirectResult} from "@firebase/auth";
import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInAnonymously, signInWithRedirect} from "firebase/auth";
import {Exercise, ExerciseSet, Modality, Muscle, Status, Workout,} from "../logic/exercises";
import {showMainPage} from "./frontPageUI";
import {addExercise, editExercise, refreshMuscles, saveExercise, showExercises} from "./editExercisesUI";
import {createWorkout, nextExercise, startWorkout} from "./workoutUI";
import {showHistoryPage} from "./historyUI";
import {DataStorage} from "../logic/dataStorage";
import {FAR_FROM_FAILURE_RIR, WorkoutPlanner} from "../logic/workoutPlanner";
import {initializeController, showPage} from "./controller";
import {showStatisticsPage} from "./statisticsUI";
import {Chart} from "chart.js/auto";
import {fatigueTest} from "../logic/fatiguePredictor";
import tippy from "tippy.js";
import 'tippy.js/themes/light-border.css';

let app: FirebaseApp;
let firestore: Firestore;
let auth: Auth;
let functions: Functions;
let dataStorage: DataStorage;
let planner: WorkoutPlanner;
export let isAnonymous = true;

export function initialize() {
    document.getElementById('sign-in-btn')?.classList.add('hidden');

    const firebaseConfig = {
        apiKey: "AIzaSyDSpETQeUQGPWD34FlDAaI8RZxTGIkfXrU",
        // authDomain: "addarep-afec4.firebaseapp.com",
        authDomain: "addrep.com",
        projectId: "addarep-afec4",
        storageBucket: "addarep-afec4.appspot.com",
        messagingSenderId: "311335102809",
        appId: "1:311335102809:web:e2fbc5670b12b23a964448",
        measurementId: "G-4CQ12G5028"
    };

// register service worker..
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            (navigator as Navigator).serviceWorker.register('/sw.js').then((registration: ServiceWorkerRegistration) => {
                console.log('Service worker registered with scope:', registration.scope);
            }, (error: any) => {
                console.log('Service worker registration failed:', error);
            });
        });
    }


    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    // initializeFirestore(app,{localCache: memoryLocalCache()});
    initializeFirestore(app, {localCache: persistentLocalCache()});
    firestore = getFirestore(app);
    auth = getAuth(app);
    functions = getFunctions(app);

// Listen for authentication state changes
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            if (user.isAnonymous) {
                document.getElementById('sign-in-btn')?.classList.remove('hidden');
                isAnonymous = true;
            } else {
                document.getElementById('sign-in-btn')?.classList.add('hidden');
                isAnonymous = false;
            }
            console.log("Signed in: " + user.isAnonymous + " " + user.email + " " + user.uid);
            user.providerData.forEach((profile) => {
                console.log("Sign-in provider: " + profile.providerId);
            });
            dataStorage = new DataStorage(firestore, user.uid);
            planner = new WorkoutPlanner(dataStorage);
            await showMainPage();
        } else {
            signInAnonymously(auth).catch((error) => {
                console.error("Error signing in anonymously:", error);
            });
        }
    });

    initializeFunctions()

    initializeController();

    getRedirectResult(auth).then((r) => {
        if (r && !r.user.isAnonymous) {
            console.log("erasing authflow from history")
            history.go(-4);
        }
    })

    initializeUI();

}

interface ExerciseMuscles {
    modalities: Modality[],
    primary: Muscle[],
    secondary: Muscle[],
}

export let exerciseMuscles: (name: string, details: string) => Promise<ExerciseMuscles>;

function initializeFunctions() {
    const exerciseMusclesFunctionCallable = httpsCallable(functions, 'exerciseMuscles');
    exerciseMuscles = async (name: string, details: string) => {
        const r = (await exerciseMusclesFunctionCallable({
            name: name,
            details: details
        })).data;
        return r as ExerciseMuscles;
    };
}

export function initializeUI() {
    document.getElementById('start-workout-btn')!.addEventListener('click', startWorkout);
    document.getElementById('edit-exercises-btn')!.addEventListener('click', showExercises);
    document.getElementById('show-history-btn')!.addEventListener('click', showHistoryPage);
    document.getElementById('show-statistics-btn')!.addEventListener('click', showStatisticsPage);

    document.getElementById('sign-in-btn')!.addEventListener('click', () => signInWithRedirect(getFirebaseAuth(), new GoogleAuthProvider()));
    document.getElementById('version-number')!.addEventListener('click', () => showPage('debug-page', null));
    document.getElementById('version-number')!.innerText = "Version: " + process.env.VERSION;

    // debug function..
    // document.getElementById('run-tests')!.addEventListener('click', test);
    // document.getElementById('run-tests')!.addEventListener('click', async () => console.log(JSON.stringify(await exerciseMuscles("front lever hold", ""))));
    document.getElementById('run-tests')!.addEventListener('click', fatigueTest);
    // document.getElementById('run-tests')!.addEventListener('click', async () => getWorkoutPlanner().debug());
    // document.getElementById('run-tests')!.classList.add("hidden");


    document.getElementById('add-exercise-btn')!.addEventListener('click', addExercise);
    document.getElementById('workout-start-btn')!.addEventListener('click', createWorkout);

    document.getElementById('completed-btn')!.addEventListener('click', () => nextExercise(Status.Completed));
    document.getElementById('failed-btn')!.addEventListener('click', () => nextExercise(Status.Failed));
    document.getElementById('skipped-btn')!.addEventListener('click', () => nextExercise(Status.Skipped));

    document.getElementById('save-button')!.addEventListener('click', saveExercise);
    document.getElementById('refresh-muscles-button')!.addEventListener('click', refreshMuscles);
    // document.getElementById('delete-button')!.addEventListener('click', () => modifyExercise('delete'));

    document.getElementById('workout-done-btn')!.addEventListener('click', showMainPage);

    tippy.setDefaultProps({
        theme: 'light-border',
        allowHTML: true,
    });
}

export function makeElementProgressBar(element: HTMLElement, progress: number, color: string) {
    if (!element.classList.contains("progress-bar")) {
        element.classList.add("progress-bar");
    }
    element.style.background = "linear-gradient(to right, " + color + " " + (100 * progress).toFixed(0) + '%, #ffffff ' + (100 * progress).toFixed(0) + '%)';
}

export class CountdownTimer {
    private endTime: number = 0;
    private timerId: NodeJS.Timeout | null = null;
    private callback: ((minutes: number, seconds: number) => void) | null = null;

    constructor() {
    }

    start(durationSeconds: number, callback: (minutes: number, seconds: number) => void) {
        this.cancel();
        this.endTime = Date.now() + durationSeconds * 1000; // Convert duration to milliseconds
        this.callback = callback;
        this.timerId = setInterval(() => {
            let remainingTime = Math.max(0, this.endTime - Date.now()); // In milliseconds
            let remainingSeconds = Math.round(remainingTime / 1000); // Convert to seconds
            let minutes = Math.floor(remainingSeconds / 60);
            let seconds = remainingSeconds % 60;
            this.callback!(minutes, seconds);
            if (minutes == 0 && seconds == 0) {
                clearInterval(this.timerId!);
                this.timerId = null;
            }
        }, 1000);
    }

    cancel() {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
    }
}

export function repsToString(reps: number, isometric: boolean): string {
    return Math.round(reps) + " " + (isometric ? "second" : "repetition") + (reps == 1 ? "" : "s")
}

let audioContext: AudioContext;

export function beep(duration: number, frequency: number, volume: number, delay: number, type: 'square' | 'sine') {
    if (!audioContext) {
        audioContext = new AudioContext();
    }
    let oscillator = audioContext.createOscillator();
    let gainNode = audioContext.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);


    gainNode.gain.value = volume;
    oscillator.frequency.value = frequency;

    oscillator.type = type;

    oscillator.start(audioContext.currentTime + delay);
    oscillator.stop(audioContext.currentTime + delay + duration);
}

export async function workoutInProgress(): Promise<boolean> {
    try {
        const workout: Workout = await getWorkoutPlanner().getWorkout()
        if (!workout.completed) {
            // we end it if, it has not yet been started, or all exercises are already done, or if over 6 hours old.
            console.log("existing workout state : " + workout.hasStarted() + " " + workout.hasNextExerciseSet() + " " + ((new Date().getTime() - workout.date.getTime()) > 1000 * 60 * 60 * 6));
            if (!workout.hasStarted() || !workout.hasNextExerciseSet() || (new Date().getTime() - workout.date.getTime()) > 1000 * 60 * 60 * 6) {
                console.log("ending workout");
                await getWorkoutPlanner().endWorkout();
            } else {
                return true;
            }
        }
    } catch (error: any) {
    }
    return false;
}

export function getFirebaseAuth(): Auth {
    return auth;
}

export function getDataStorage(): DataStorage {
    return dataStorage;
}

export function getWorkoutPlanner(): WorkoutPlanner {
    return planner;
}

export function makeToolTip(element: Element, tooltip: string) {
    element.setAttribute("data-tippy-content", tooltip);
}

export function enableTooltips() {
    tippy('[data-tippy-content]');
}

export function createCheckBox(name: string, selected: boolean, tooltip: string | undefined): HTMLElement {
    const divWrapper = document.createElement('div');
    divWrapper.className = "checkbox-wrapper";
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.checked = selected;
    checkbox.id = name + "-label" + Math.random();
    checkbox.className = "generated-checkbox";
    divWrapper.appendChild(checkbox);

    const label = document.createElement('label');
    label.textContent = name; // Replace with the modality name
    label.htmlFor = checkbox.id;
    label.classList.add("checkbox-label");
    if (tooltip) {
        makeToolTip(label, tooltip);
    }
    divWrapper.appendChild(label);

    return divWrapper;
}

export function readCheckboxes(div: HTMLElement): string[] {
    let names: string[] = [];
    for (let wrapper of Array.from(div.getElementsByClassName("checkbox-wrapper"))) {
        let checkbox = wrapper.getElementsByClassName("generated-checkbox")[0]! as HTMLInputElement;
        let label = wrapper.getElementsByClassName("checkbox-label")[0]! as HTMLElement;
        console.log(checkbox.checked + " " + label.innerText!);
        if (checkbox.checked) {
            names.push(label.innerText!);
        }
    }
    return names;
}

export function createExpandable(details: string): HTMLElement {
    let div = document.createElement("span");
    let divFirst = document.createElement("span");
    divFirst.textContent = ", " + details;
    divFirst.classList.add("hidden");
    let divSecond = document.createElement("span");
    divSecond.textContent = "(details)";
    divSecond.classList.add("semi-button");
    divSecond.addEventListener("click", (event) => {
        event.stopPropagation();
        if (divFirst.classList.contains("hidden")) {
            divSecond.textContent = "(hide)";
        } else {
            divSecond.textContent = "(details)"
        }
        divFirst.classList.toggle("hidden");
    });
    div.appendChild(divFirst);
    div.appendChild(document.createTextNode(" "));
    div.appendChild(divSecond);
    return div;
}

export function createExerciseDiv(exerciseSet: ExerciseSet, exercise: Exercise, showRIR: boolean, showStatus: boolean, linkToExercise: boolean): HTMLDivElement {
    let exerciseDiv = document.createElement('div');
    exerciseDiv.appendChild(document.createTextNode(exerciseSet.reps + " " + (exercise.isModality(Modality.ISOMETRIC) ? "sec" : "x") + " "));
    let nameSpan = document.createElement("span");
    nameSpan.textContent = exercise.name;
    if (linkToExercise) {
        nameSpan.addEventListener("click", () => editExercise(exercise))
    }
    // nameSpan.classList.add("semi-button");
    exerciseDiv.appendChild(nameSpan);
    if (exercise.isModality(Modality.UNILATERAL)) {
        if (exerciseSet.secondary) {
            exerciseDiv.appendChild(document.createTextNode(" left side"));
        } else {
            exerciseDiv.appendChild(document.createTextNode(" right side"));
        }
    } else {
        // exerciseDiv.appendChild(document.createTextNode(" "));
    }
    if (showRIR && exerciseSet.rir < FAR_FROM_FAILURE_RIR) {
        exerciseDiv.appendChild(document.createTextNode(" (@" + exerciseSet.rir + "RIR)"));
    }
    if (exercise.details.length != 0) {
        exerciseDiv.appendChild(createExpandable(exercise.details));
    }
    if (showStatus) {
        if (exerciseSet.status == Status.Completed) {
        } else if (exerciseSet.status == Status.Failed) {
            exerciseDiv.appendChild(document.createTextNode(' FAILED'));
        } else if (exerciseSet.status == Status.Skipped) {
            exerciseDiv.appendChild(document.createTextNode(' SKIPPED'));
        }
    }
    return exerciseDiv;
}


export function smooth(array: number[], window: number): number[] {
    let r = [];
    for (let i = 0; i < array.length; ++i) {
        let v = array[i];
        let t = 1;
        for (let j = 1; j < window; ++j) {
            const w = 1 - (j / window);
            // const w = 1
            if (i - j >= 0) {
                v += array[i - j] * w;
                t += w;
            }
            if (i + j < array.length) {
                v += array[i + j] * w;
                t += w;
            }
        }
        r.push(v / t);
    }
    return r;
}

interface Line {

    name: string;
    color: string | undefined;
    data: number[];
    ignoreForMax: boolean;

}

export class ChartFactory {

    lines: Line[] = [];
    legends = true;

    constructor(private id: string, private labels: string[]) {

    }

    setLegends(visibile: boolean) {
        this.legends = visibile;
    }

    addLine(name: string, data: number[], color: string | undefined, ignoreForMax: boolean = false) {
        this.lines.push({
            name: name,
            color: color,
            data: data,
            ignoreForMax: ignoreForMax,
        });
    }

    create(): Chart {
        const maxValue = this.lines.map((line) => line.ignoreForMax ? 0 : line.data.reduce((a, b) => isNaN(b) ? a : Math.max(a, b), 0)).reduce((a, b) => Math.max(a, b), 0);
        // this.lines.forEach((line) => console.log(line.name + " max is " + line.data.reduce((a, b) => isNaN(b) ? a : Math.max(a, b), 0)));
        // console.log("max value " + maxValue);
        const customOptions = {
            interaction: {
                intersect: false,
                mode: "nearest" as 'x' | 'nearest' | 'y' | 'index' | 'dataset' | 'point',
            },
            plugins: {
                legend: {
                    display: this.legends
                }
            },
            legend: {
                labels: {
                    fontSize: 12
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 12
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: 12
                        }
                    },
                    max: Math.max(maxValue + 1, Math.round(maxValue * 1.2)),
                }
            },
            maintainAspectRatio: false,
            responseive: false,
            spanGaps: true
        };

        // noinspection TypeScriptValidateTypes
        return new Chart(
            document.getElementById(this.id) as HTMLCanvasElement,
            {
                type: 'line',
                options: customOptions,
                data: {
                    labels: this.labels,
                    datasets: this.lines.map((line: Line) => {
                        return {
                            label: line.name,
                            tension: 0.4,
                            backgroundColor: line.color,
                            borderColor: line.color,
                            data: line.data
                        }
                    })
                }
            }
        );
    }
}
