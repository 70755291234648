import {Exercise, ExerciseSet, Modality, Muscle} from "./exercises";
import {getDataStorage} from "../ui/tools";

function evaluateExerciseValueWithCriteria(exercises: Exercise[], sets: ExerciseSet[], indexNext: number, indexCandidate: number, match: (exercise: Exercise) => boolean): number {
    if (!match(exercises[indexCandidate])) {
        return 0;
    }
    let count = 0;
    let preceding = -1;
    let remaining = 0;
    for (let i = 0; i < exercises.length; i++) {
        if (sets[i].secondary) {
            continue;
        }
        if (match(exercises[i])) {
            count += 1;
            if (i < indexNext) {
                preceding = i;
            } else if (i >= indexNext) {
                remaining += 1;
            }
        }
    }

    if (count > 0) {
        const idealDistance = remaining / (1 + exercises.length - preceding);
        // const idealDistance = (count + 1) / (1 + sets.length);
        const actualDistance = indexNext - preceding;
        return (idealDistance - actualDistance) ** 2 * remaining ** 3;
        // return idealDistance
    }
    return 0;
}


function evaluateExerciseValue(exercises: Exercise[], sets: ExerciseSet[], indexNext: number, indexCandidate: number): number {

    // If the candidate exercise is not the first of its type from indexNext onwards, return 0.
    for (let i = indexNext; i < indexCandidate; i++) {
        if (exercises[i].id == exercises[indexCandidate].id) {
            return 0;
        }
    }


    // schedule other side right after first side.
    if (exercises[indexCandidate].isModality(Modality.UNILATERAL) && !sets[indexCandidate].secondary) {
        return 100000;
    }

    let value = 0.01;

    for (let muscle of Object.keys(Muscle) as Muscle[]) {
        value += evaluateExerciseValueWithCriteria(exercises, sets, indexNext, indexCandidate, (exercise: Exercise) => exercise.isPrimaryMuscle(muscle));
    }

    value += 0.5 * evaluateExerciseValueWithCriteria(exercises, sets, indexNext, indexCandidate, (exercise: Exercise) => exercise.id == exercises[indexCandidate].id);

    value += 0.25 * evaluateExerciseValueWithCriteria(exercises, sets, indexNext, indexCandidate, (exercise: Exercise) => exercise.isModality(Modality.MOBILITY));

    // increase priority for exercises where we still have testing set coming up.
    for (let i = indexCandidate; i < sets.length; ++i) {
        if (exercises[i].id == exercises[indexCandidate].id && sets[i].reps > exercises[i].getWorkingSetReps(new Date())) {
            value *= 1.5;
            break;
        }
    }
    // increase priority of skill practice..
    if (exercises[indexCandidate].isModality(Modality.SKILL)) {
        value *= 5;
    }
    if( exercises[indexCandidate].isModality(Modality.STRENGTH)) {
        value *= 3;
    }

    // // increase priority of low rep work..
    // value *= 3/(3+Math.sqrt(exercises[indexCandidate].getWorkingSetReps(new Date())));

    // console.log(indexNext + " : " + exercises[indexCandidate].name + " : " + value)

    return value;
}

function pickExercise(exercises: Exercise[], sets: ExerciseSet[], indexNext: number): number {
    let bestIndex = 0;
    let bestValue = 0;
    for (let i = indexNext; i < sets.length; ++i) {
        const value = evaluateExerciseValue(exercises, sets, indexNext, i);
        if (value > bestValue) {
            bestIndex = i;
            bestValue = value;
        }
    }
    return bestIndex;
}

export async function sortExerciseSets(sets: ExerciseSet[], indexStarting: number): Promise<ExerciseSet[]> {
    let exercises: Exercise[] = await Promise.all(sets.map((x) => getDataStorage().getExercise(x.exerciseId)));
    for (let i = indexStarting; i < sets.length; ++i) {
        let bestIndex = pickExercise(exercises, sets, i);
        if (bestIndex != i) {
            sets = sets.slice(0, i).concat([sets[bestIndex]], sets.slice(i, bestIndex), sets.slice(bestIndex + 1, sets.length))
            exercises = exercises.slice(0, i).concat([exercises[bestIndex]], exercises.slice(i, bestIndex), exercises.slice(bestIndex + 1, sets.length))
        }
    }
    return sets;
}

export async function sortWorkout(sets: ExerciseSet[]): Promise<ExerciseSet[]> {
    return sortExerciseSets(sets, 0);
}

